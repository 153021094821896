import React, { useEffect, useState } from "react";
import { Shell, Carousel, Card, Text } from "@StixNext/mojito-ui";
import { Column, Grid, Row, SearchInput, Dropdown } from "@StixNext/mojito-ui";
import * as Images from "./common/watch-free";
import * as actions from "../actions/Programmes";
import { connect } from "react-redux";
import { displayFilter, formatDate } from "../utils/utils";
import StarIcon from "../assets/images/star_icon.svg";
import GreyOverlay from "../assets/images/grey_overlay.svg";

const updatedOptions = { year: "numeric", month: "short" };

const Upcomming = (props) => {
  let { videoOnDemandList } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filterValue, setFilterValue] = useState("");
  const [slidesEvents, setSlidesEvents] = useState([]);
  let tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  const [sortType, setSortType] = useState("1");
  const sortOptions = [
    { text: "Latest Release", value: "1" },
    { text: "Alphabetical Order (A - Z)", value: "2" },
    { text: "Alphabetical Order (Z - A)", value: "3" },
  ];

  const onSortChange = (option) => {
    setSortType(option);
  };

  const isOverlay = (slide) => {
    if (!props.loginDetails.authenticated) return false;
    else if (slide.isFreeEvent) return false;
    else if (
      props.loginDetails.authenticated &&
      (props.transactionDetails.hideBuyButton ||
        (props.transactionDetails.expireTransactionData.length > 0 &&
          props.transactionDetails.expireTransactionData.some(
            (single) =>
              single.product.sessionIds &&
              single.product.sessionIds.includes(slide.id)
          )) ||
        props.watchpartyDetails.invitationSessionIds.includes(slide.id))
    )
      return false;
    else return true;
  };

  const removeTags = (title) =>
    title.replace(/(<([^>]+)>)/gi, "").replace(/(?:&nbsp;|amp;)/g, "");

  const sortByProperty = (property) => {
    return (a, b) => {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    };
  };

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  const onFilter = (e) => {
    setFilterValue(e.target.value);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleClick = (eventId = "FP2") => {
    localStorage.setItem(
      "pathDetail",
      JSON.stringify({ eventId, path: "upcoming" })
    );
    props.history.push(`/watch/${eventId}`);
  };

  useEffect(() => {
    props.fetchPlayback();
  }, []);

  const setScheduleFilter = () => {
    const sortField = sortType !== "1" ? "title" : "startTime";
    const filteredVideoOnDemandList = videoOnDemandList.filter((item) =>
      item.isNewRelease ? item.isNewRelease == false : true
    );
    let data = filteredVideoOnDemandList
      .sort(sortByProperty("startTime"))
      .filter((slide) => {
        let title = slide.title
          ? slide.title
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/(?:&nbsp;|amp;)/g, "")
          : "";
        let writeUp = slide.writeUp
          ? slide.writeUp
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/(?:&nbsp;|amp;)/g, "")
          : "";
        let featuredPresenters = slide.featuredPresenters
          ? slide.featuredPresenters
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/(?:&nbsp;|amp;)/g, "")
          : "";
        let synopsis = slide.synopsis
          ? slide.synopsis
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/(?:&nbsp;|amp;)/g, "")
          : "";

        return filterValue
          ? (title.toUpperCase().includes(filterValue.toUpperCase()) ||
              writeUp.toUpperCase().includes(filterValue.toUpperCase()) ||
              featuredPresenters
                .toUpperCase()
                .includes(filterValue.toUpperCase()) ||
              synopsis.toUpperCase().includes(filterValue.toUpperCase())) &&
              new Date(slide.startTime) > tomorrow
          : new Date(slide.startTime) > tomorrow;
      })
      .sort((a, b) =>
        (sortType === "1"
          ? new Date(a[sortField])
          : sortType === "2"
          ? removeTags(a[sortField])
          : removeTags(b[sortField])) >
        (sortType === "1"
          ? new Date(b[sortField])
          : sortType === "2"
          ? removeTags(b[sortField])
          : removeTags(a[sortField]))
          ? 1
          : -1
      );
    setSlidesEvents(data);
  };

  useEffect(() => {
    setScheduleFilter();
  }, [videoOnDemandList, filterValue, sortType]);

  return (
    <Shell.Body className="playback-container">
      <div className="toggle-div">
        <div className="card-filter">
          <SearchInput placeholder="Keyword Search" onChange={onFilter} />
        </div>
        <div className="toggle-cover">
          <Grid>
            <Row className="sort_by_row">
              <Text as={"h5"} className="show-date">
                Sort By
              </Text>
              <Dropdown
                id="sort-dropdown"
                options={sortOptions}
                mulitple={false}
                Width={250}
                value={sortType}
                placeholder="Select"
                onChange={(item) => onSortChange(item.value)}
              />
            </Row>
          </Grid>
        </div>
      </div>
      <Grid>
        <Row>
          {slidesEvents.length > 0
            ? slidesEvents.map((slide, index) => {
                let imageArray = slide.thumbNail
                  ? slide.thumbNail.split(",")
                  : "";
                let image =
                  imageArray && imageArray.length > 1
                    ? imageArray[1]
                    : imageArray[0];
                return (
                  <div className="contestant-tile" key={index}>
                    <Card
                      key={index}
                      className={"video-slides"}
                      onClick={() => handleClick(slide?.id)}
                      noPadding={false}
                      noBoxShadow={false}
                      noBorder={true}
                      noBorderRadius={true}
                    >
                      {isOverlay(slide) && (
                        <img className="overlay_img" src={GreyOverlay} alt="" />
                      )}
                      <img src={image} alt="" />
                      {props.watchpartyDetails.invitationSessionIds.includes(
                        slide.id
                      ) && (
                        <div
                          className={`vip_tag ${
                            !slide.isFreeEvent && "vip-container"
                          }`}
                        >
                          <div className="vip_tag_star">
                            <img src={StarIcon} />
                          </div>
                          <div className="vip_tag_text">
                            <Text>VIP Room</Text>
                          </div>
                        </div>
                      )}
                      {!slide.isFreeEvent && (
                        <div
                          className={`premium-tag ${
                            isOverlay(slide) && "overlay_premium"
                          }`}
                        >
                          <div className="premium-tag-text">
                            <Text>Premium</Text>
                          </div>
                        </div>
                      )}
                      <div className="meta-details">
                        <div
                          className={"title-tiles"}
                          dangerouslySetInnerHTML={{ __html: slide?.title }}
                        />
                        <div>
                          <Text as="p" className="style-p">
                            {"Duration: " + slide?.duration + " mins"}{" "}
                          </Text>
                          <Text as="p" className="style-p premiers">
                            {"Premieres: " + formatDate(slide.startTime)} <br />
                          </Text>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })
            : ""}
        </Row>
      </Grid>
    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  videoOnDemandList: displayFilter(state.Programmes.programmesList),
  watchpartyDetails: state.WatchParty,
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlayback: () => dispatch(actions.fetchProgrammes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upcomming);
