import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import BaseApp from "./App";
import { BrowserRouter as Router } from "react-router-dom";
// import i18n (needs to be bundled ;))
import "./utils/i18n";
import Keycloak from "keycloak-js";
import { KeycloakProvider } from "@react-keycloak/web";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { RectangleSection3Icon } from "@StixNext/mojito-ui/dist/Icons";

window.process = {};

const keycloakJs = Keycloak(process.env.REACT_APP_BOUNCERCONFIG_URL);
const keycloakProviderInitConfig = {
  onLoad: "check-sso",
  checkLoginIframe: false,
  checkLoginIframeInterval: 10,
};
let onKeycloakEvent = (event, error) => {};
let onKeycloakTokens = (tokens) => {};

ReactDOM.render(
  <KeycloakProvider
    keycloak={keycloakJs}
    initConfig={keycloakProviderInitConfig}
    onEvent={onKeycloakEvent}
    onTokens={onKeycloakTokens}
  >
    <Router>
      <BaseApp />
    </Router>
  </KeycloakProvider>,
  document.getElementById("root")
);
