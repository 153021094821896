import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Text, Row, AccountSideBar } from "@StixNext/mojito-ui";
import AccountDetails from "./AccountDetails";
import PaymentMethod from "./PaymentMethod";
import PassSubscriptions from "./PassSubscriptions";
import BillingHistory from "./BillingHistory";
import Communications from "./Communications";

function MyAccount(props) {
  const [pageNo, setPageNo] = useState("1");
  const renderPage = () => {
    switch (pageNo) {
      case 1:
        return <AccountDetails />;
      case 2:
        return <PaymentMethod />;
      case 3:
        return <PassSubscriptions />;
      case 4:
        return <BillingHistory />;
      case 5:
        return <Communications />;
      default:
        return <AccountDetails />;
    }
  };
  return (
    <div className="acc-div">
      <Row>
        <Text className="acc-head">My Account</Text>
      </Row>
      <div className="acc-body">
        <div className="acc-leftbar">
          <AccountSideBar
            pageNo={pageNo}
            items={[
              {
                menu_name: "Account Details",
                onclick: () => setPageNo(1),
              },
              {
                menu_name: "Payment Method",
                onclick: () => setPageNo(2),
              },
              {
                menu_name: "Pass Subscriptions",
                onclick: () => setPageNo(3),
              },
              // {
              //   menu_name: "Billing History",
              //   onclick: () => setPageNo(4),
              // },
              // {
              //   menu_name: "Communications",
              //   onclick: () => setPageNo(5),
              // },
            ]}
          ></AccountSideBar>
        </div>
        <div className="acc-line"></div>
        <div className="acc-right">{renderPage()}</div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
