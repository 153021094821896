import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text, Button, DropInPayment, RadioButton } from "@StixNext/mojito-ui";

import main from "../../assets/images/stage.png";
import loading from "../../assets/images/loading.gif";

import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import * as prodActions from "../../actions/Products";

function BuyProduct(props) {
  useEffect(() => {
    props.fetchProducts();
  }, []);

  return (
    <>
      <div
        style={{
          padding: "10px 0px",
        }}
      >
        <Grid>
          <Row>
            <Column xl={5} xs={12}>
              <img className="main_image" src={main} alt="icon" />
            </Column>
            <Column
              xl={7}
              xs={12}
              className={"payment-section-text"}
              style={{ flexFlow: "column", alignItems: "flex-start" }}
            >
              <Row>
                <Text as="h4" className="pass_head">
                  Please select your preferred access pass
                </Text>
              </Row>
              <Row>
                <div
                  className={
                    props.productDetails.productLoading
                      ? "loader-product-show"
                      : "loader-pro"
                  }
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "190px", height: "auto" }}
                      src={loading}
                      alt="Avatar"
                    />
                  </div>
                </div>
              </Row>
              {props.productDetails.productList &&
              props.productDetails.productList.length > 0 &&
              !props.productDetails.productLoading
                ? props.productDetails.productList.map((o, k) => {
                    var travellabel = o.label
                      .toLowerCase()
                      .split("traveller")[1];
                    var balword = o.label.split(travellabel)[0];
                    return (
                      <Row className="product_section" key={k}>
                        <Column xl={9} className="radio_colummn">
                          <RadioButton
                            labelText={o.label}
                            value={o.price}
                            name="radio"
                            id={o.id}
                            checked={true}
                            onChange={(e) => {
                              var selectedProduct =
                                props.productDetails.productList.filter(
                                  function (item) {
                                    return item.id === e.target.id;
                                  }
                                );
                              props.changeProductValues(
                                "selectedProduct",
                                selectedProduct[0]
                              );
                              props.changeTransactionValues(
                                "productPrice",
                                e.target.value
                              );
                            }}
                          >
                            {o.description ? (
                              <span className="info_section">
                                ({o.description})
                              </span>
                            ) : null}
                          </RadioButton>
                        </Column>
                        <Column xl={2} sm={3} className="approx_colummn">
                          <Text as="h6">
                            <strong>{`S$${o.price}.00`}</strong>
                          </Text>
                          {/* <span style={{ fontSize: "12px" }}>
                          (approx. USD{Number(o.usdapprox).toFixed(2)})
                        </span> */}
                        </Column>
                      </Row>
                    );
                  })
                : !props.productDetails.productLoading && (
                    <Row className="no_prod_row">
                      <Text as="h4" className="no_product_head">
                        All products have been sold out!!
                      </Text>
                    </Row>
                  )}
              <Row style={{ width: "100%", justifyContent: "flex-end" }}>
                <Button
                  primary
                  disabled={
                    props.transactionDetails.productPrice ? false : true
                  }
                  onClick={(e) => {
                    if (
                      !props.loginDetails.authToken &&
                      props.loginDetails.accessToken
                    ) {
                      props.getAuthToken(props.loginDetails.accessToken);
                    }
                    props.changeTransactionValues("loader", "block");
                    props.changeTransactionValues("paymentLoader", "none");
                    props.changeTransactionValues("transactionStatus", 9);
                  }}
                >
                  Buy Now
                </Button>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column>
              <span className="buy_footer">
                *Prices listed are inclusive of SISTIC booking fee and shipping
                fee. Customers are responsible for any additional local taxes
                once shipped.
              </span>
              <br />
              <span className="buy_footer">
                **The currency of sales will be transacted in Singapore dollars.
              </span>
              <br />
              <span className="buy_footer">
                *** Prices may vary according to the exchange rate of the bank
                billing date.
              </span>
            </Column>
          </Row>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: (token) => dispatch(actions.getAuthToken(token)),
  fetchTransaction: (data, clicked, sessionId) =>
    dispatch(transActions.fetchTransaction(data, clicked, sessionId)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyProduct);
