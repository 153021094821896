import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Column,
  Text,
  Button,
  BouncerContextHook,
  SisticLivePlayerAlt,
  SisticDecastPlayer,
  ChatIcon,
  FullScreenExitIcon,
  FullScreenIcon,
  EditIcon,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";
import livestreamdot from "../assets/images/icons/livestream_dot.svg";
import Donation from "../assets/images/donation.png";
import DonationLogo from "../assets/images/donation-logo.png";
import Arrow from "../assets/images/leftarrow.png";
import loadingGif from "../assets/images/accept_loading.gif";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import * as watchactions from "../actions/WatchParty";
import * as userActions from "../actions/UserActivities";
import LiveChat from "./LiveChat";
import PrivateChat from "./PrivateChat";
import LikeBox from "./LikeBox";
import Iframe from "react-iframe";
import { formatDateTime, userDetails, numberToString } from "../utils/utils";
import { pushEventToData } from "../utils/Datalayer";
import screenful from "screenfull";

function VideoDetail(props) {
  const { history, location = {} } = props;
  const loginContext = BouncerContextHook();
  const [ChatOrQA, setChatOrQA] = useState("addon");
  const [play, setPlay] = useState(false);
  const [end, setEnd] = useState(false);
  const [start, setStart] = useState(false);
  const [isEventPush, setIsEventPush] = useState(false);
  const [showInvitationBanner, setInvitationBanner] = useState(false);
  const [isVipButtonClicked, setIsVipButtonClicked] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isMobile, setisMobile] = useState(window.innerWidth <= 823);
  const [theatreView, setTheatreView] = useState(false);
  const [endPremiumDate, setEndPremiumDate] = useState(false);
  const parentContainerRef = useRef(null);

  useEffect(() => {
    setIsSafari(
      window.navigator.userAgent.match(/OS X.*Safari/) &&
        !window.navigator.userAgent.match(/Chrome/)
    );
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.addEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  //to get the window screen width
  const handleWindowSizeChange = () => {
    setisMobile(window.innerWidth <= 823);
  };

  const toggleTheatreView = () => {
    screenful.toggle(parentContainerRef.current);
    setTheatreView(!theatreView);
  };

  useEffect(() => {
    var todayDate = moment(new Date(moment.utc().format()));
    var endPremiumDate = moment(
      new Date(moment.utc(props?.overviewDetail?.endPremiumPass).format())
    );
    setEndPremiumDate(endPremiumDate.diff(todayDate, "seconds") <= 0);
  }, [props.overviewDetail]);

  let interval = null;

  function myFunction(startDateTime, duration) {
    interval = setInterval(function () {
      var serverDate = moment(new Date(moment.utc().format()));
      var indiaDate = moment(new Date(moment.utc(startDateTime).format()));
      setStart(true);
      if (indiaDate.diff(serverDate, "seconds") < 1) {
        if (!play) {
          setPlay(true);
          setStart(false);
        }
        clearInterval(interval);
      }
    }, 1000);
  }

  let getDegitalPass = async (e, isFree) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", isFree ? "seasonfreepass" : "seasonpass");
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          "",
          ""
        );
      }
    }, 1000);
  };
  let getSingleDegitalPass = async (e) => {
    let progammeId = props.match.params.id;
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", "singlepass");
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          progammeId,
          ""
        );
      }
    }, 1000);
  };
  useEffect(() => {
    props.changeProductValues("isFreeEvntClicked", false);
    props.fetchChatProperties();
    props.fetchShortCodeProperties();
    let progammeId = props.match.params.id;
    if (process.env.REACT_APP_DRYRUN_PROGRAMME_IDS.includes(progammeId)) {
      props.fetchDryRunProgrammeDetail(progammeId);
    } else {
      props.fetchProgrammeDetail(progammeId);
    }
    setIsVipButtonClicked(false);
    props.changeWatchPartyValues("canJoinRoom", false);
    props.changeWatchPartyValues("vipSessionDetails", {});
    return () => {
      clearInterval(interval);
      props.changeProgrammesValues("programme", []);
    };
  }, []);

  useEffect(() => {
    if (props.productDetails.checkProductList.length > 0) {
      props.productDetails.checkProductList.forEach((expireProd) => {
        let expireInterval = null;
        let isFixedExpiryDate = !!expireProd.isFixedExpiryDate
          ? expireProd.isFixedExpiryDate
          : false;
        let validityInMonths = !!expireProd.validityInMonths
          ? parseInt(expireProd.validityInMonths)
          : 12;
        if (!isFixedExpiryDate) {
          let expdt = new Date();
          expireProd.expiresOn = new Date(
            expdt.setMonth(expdt.getMonth() + validityInMonths)
          ).toISOString();
        }
        let utcString = new Date(expireProd.expiresOn).toUTCString();
        expireInterval = setInterval(function () {
          var serverDate = moment(new Date(moment.utc().format()));
          var indiaDate = moment(new Date(moment.utc(utcString).format()));
          if (indiaDate.diff(serverDate, "seconds") < 1) {
            props.changeProductValues(
              "checkProductList",
              props.productDetails.checkProductList.filter(
                (item) => item.id !== expireProd.id
              )
            );
            clearInterval(expireInterval);
          }
        }, 1000);
      });
    }
  }, [props.productDetails.checkProductList]);
  useEffect(() => {
    if (props.programmeDetails.programme.length > 0) {
      let progammeId = props.match.params.id;
      props.fetchCheckProducts(
        progammeId,
        "singlePass",
        props.programmeDetails.programme[0]?.isFreeEvent
      );
      myFunction(
        props.programmeDetails.programme[0].startTime,
        props.programmeDetails.programme[0].duration
      );
      if (
        props.programmeDetails.programme[0].ChatFlag &&
        props.programmeDetails.programme[0].QAFlag
      ) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].ChatFlag) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].QAFlag) {
        setChatOrQA("QA");
      }
    }
  }, [props.programmeDetails.programme]);

  const getSynopsis = (writeUp) => {
    let regex = /.*SYNOPSIS.*?(?=\<br>)<br>(?<synopsis>.+?(?=\<br>|&))/;
    let { groups } = writeUp.match(regex);
    return groups.synopsis;
  };

  useEffect(() => {
    let sessionId = props.match.params.id;
    let filterBySession = props.watchpartyDetails.invitations.filter((item) => {
      return item.session === sessionId;
    });
    setInvitationBanner(filterBySession.length > 0 ? true : false);
  }, [props.watchpartyDetails.invitations]);

  const joinRoom = () => {
    props.changeWatchPartyValues("loadingAccept", true);
    let sessionId = props.match.params.id;
    let invitation = props.watchpartyDetails.invitations.filter(
      (item) => item.session === sessionId
    );
    props.checkoutAndJoinRoom(props.loginDetails.user, invitation[0]);
  };

  useEffect(() => {
    if (isVipButtonClicked) {
      joinRoom();
    }
    if (
      props.watchpartyDetails.canJoinRoom &&
      Object.keys(props.watchpartyDetails.vipSessionDetails).length > 0 &&
      isVipButtonClicked
    ) {
      history.push(
        `/watch/${props.watchpartyDetails.vipSessionDetails.session}/${props.watchpartyDetails.vipSessionDetails.roomid}`
      );
    }
  }, [
    props.watchpartyDetails.canJoinRoom,
    props.watchpartyDetails.vipSessionDetails,
    isVipButtonClicked,
  ]);

  const registerUserLogs = async (eventType, otherEventInfo) => {
    let deviceDetail = await userDetails().then((data) => {
      return data;
    });
    props.createUserLogs(
      {
        ...props.loginDetails.user,
        sessionId: props.loginDetails.keycloak.sessionId,
      },
      deviceDetail,
      {
        ...props.programmeDetails.programme[0],
        eventDetailInfo: JSON.stringify({ ...otherEventInfo }, numberToString),
      },
      eventType
    );
  };

  useEffect(() => {
    if (
      props?.programmeDetails?.programme?.length > 0 &&
      props?.loginDetails?.authenticated &&
      (props.transactionDetails.hideBuyButton ||
        (props.transactionDetails.expireTransactionData.length > 0 &&
          (props.transactionDetails.expireTransactionData.some(
            (single) =>
              single.product.sessionIds &&
              single.product.sessionIds.includes(
                props?.programmeDetails?.programme[0]?.id
              )
          ) ||
            (props.programmeDetails.programme[0].isFreeEvent &&
              props.transactionDetails.expireTransactionData.some(
                (single) =>
                  single.product?.isSeasonPass === "true" &&
                  single.product?.isFreeEvent === "true"
              ))))) &&
      play &&
      !start &&
      !isEventPush
    ) {
      pushEventToData("stream_watched", [
        {
          item_id: props?.programmeDetails?.programme[0]?.id,
          item_name: props?.programmeDetails?.programme[0]?.title
            ?.replace(/(<([^>]+)>)/gi, "")
            ?.replace(/(?:&nbsp;|amp;)/g, ""),
          start_time: moment(new Date())
            .tz("Asia/Singapore")
            .format("DD MMM YYYY hh:mm:ss a"),
        },
      ]);
      setIsEventPush(true);
    }
  }, [
    props.programmeDetails.programme,
    props.loginDetails.authenticated,
    props.transactionDetails,
    play,
    start,
    isEventPush,
  ]);

  return (
    <>
      {props.programmeDetails.programme.length > 0 &&
        props.programmeDetails.programme.map((eachValue, key) => {
          const inPreviewMode = localStorage.getItem("inPreviewMode");
          const value =
            inPreviewMode === "true" && eachValue.preview
              ? eachValue.preview
              : eachValue;
          let progammeSessionId = props.match.params.id;
          let imageArray = value.thumbNail ? value.thumbNail.split(",") : "";
          let image =
            imageArray && imageArray.length > 1 ? imageArray[1] : imageArray[0];
          let synopsis = value.synopsis ?? getSynopsis(value.writeUp);
          return (
            <div className="row vodContainer adjustment">
              <div className="container">
                {showInvitationBanner && (
                  <div className="invite_banner">
                    <div className="invite_banner_div">
                      <Text className="invite_banner_text">
                        You are invited to a Private VIP Room of this show!
                      </Text>
                    </div>
                    <div className="invite_banner_btn">
                      <Button
                        primary
                        onClick={() => setIsVipButtonClicked(true)}
                      >
                        {props.watchpartyDetails.loadingAccept ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              className="img-fluid"
                              src={loadingGif}
                              alt="Avatar"
                            />
                          </div>
                        ) : (
                          "Enter VIP Room"
                        )}
                      </Button>
                    </div>
                  </div>
                )}
                <div className="title title-nav">
                  <Link
                    to={
                      localStorage.getItem("prevPath")
                        ? localStorage.getItem("prevPath")
                        : "/"
                    }
                  >
                    <img src={Arrow} alt="icon" />
                  </Link>
                  <Text as="captionblock" className="titletext">
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexFlow: "row",
                        alignItems: "center",
                        columnGap: "6px",
                      }}
                    >
                      Live stream <img src={livestreamdot} alt="icon" />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: value.title }} />
                    <div style={{ fontSize: "13px", fontWeight: "100" }}>
                      {`${formatDateTime(value.startTime)} @ SSOLOUNGE`}
                    </div>
                  </Text>
                </div>
                <div className={`wholediv ${isSafari ? "safari" : ""}`}>
                  {props.loginDetails.authenticated &&
                    (props.transactionDetails.hideBuyButton ||
                      (props.transactionDetails.expireTransactionData.length >
                        0 &&
                        (props.transactionDetails.expireTransactionData.some(
                          (single) =>
                            single.product.sessionIds &&
                            single.product.sessionIds.includes(
                              progammeSessionId
                            )
                        ) ||
                          (value.isFreeEvent &&
                            props.transactionDetails.expireTransactionData.some(
                              (single) =>
                                single.product?.isSeasonPass === "true" &&
                                single.product?.isFreeEvent === "true"
                            ))))) && (
                      <div
                        className={`videodiv disablechat${
                          !play && start ? " opac_ctrl" : ""
                        }`}
                      >
                        {value?.turnOnStaticBanner && value?.staticBanner ? (
                          <img
                            src={value?.staticBanner}
                            width="100%"
                            height={"100%"}
                          />
                        ) : (
                          <SisticLivePlayerAlt
                            bgColor="white"
                            color="black"
                            className="video-player"
                            playerItems={{
                              src: value?.videoURL,
                              stopChat: value?.chatFlag
                                ? !value?.chatFlag
                                : true || value?.id == "FPPROGRAMMETEST",
                              // setIsEditName: setIsEditName,
                              // isEditName: isEditName,
                              registerUserLogs: registerUserLogs,
                              chatContent: (
                                <div>
                                  <Iframe
                                    url={
                                      process.env.PUBLIC_URL +
                                      `/chat.html?sessionId=${
                                        value?.chatSessionId
                                      }&firstname=${
                                        props.loginDetails.user.firstname
                                      }&email=${
                                        props.loginDetails.user.email
                                      }&userid=${
                                        props.loginDetails.user.userid
                                      }&sessioncode=${
                                        value.sessionCode
                                      }&username=${btoa(
                                        props.loginDetails.user.username
                                      )}`
                                    }
                                    width="100%"
                                    height="413px"
                                    id="myId"
                                    className="pigeonhole-iframe"
                                    display="initial"
                                    position="relative"
                                  />
                                </div>
                              ),
                              enableChat: value?.chatFlag,
                              // overlapReaction: (
                              //   <Iframe
                              //     url={
                              //       process.env.PUBLIC_URL +
                              //       `/reaction.html?sessionId=${
                              //         value?.chatSessionId
                              //       }&firstname=${
                              //         props.loginDetails.user.firstname
                              //       }&email=${
                              //         props.loginDetails.user.email
                              //       }&userid=${
                              //         props.loginDetails.user.userid
                              //       }&sessioncode=${
                              //         value.sessionCode
                              //       }&username=${btoa(
                              //         props.loginDetails.user.username
                              //       )}`
                              //     }
                              //     width="100%"
                              //     height="100%"
                              //     id="myId1"
                              //     className="pigeonhole-reactions-widget"
                              //     display="initial"
                              //     position="relative"
                              //   />
                              // ),
                              reactionContent: (
                                <div className="in-player-likes">
                                  <LikeBox
                                    slide={value}
                                    session={props.match.params.id}
                                  />
                                </div>
                              ),
                            }}
                          />
                        )}
                      </div>
                    )}
                  {!play && start && (
                    <div
                      className={`text_div ${
                        value.ChatFlag || value.QAFlag ? "" : "disablechat"
                      }`}
                    >
                      <img src={image}></img>
                      <p>
                        Join us from
                        <br />
                        <Moment format="hh:mma">
                          {value.startTime}
                        </Moment> SGT,{" "}
                        <Moment format="DD MMM YYYY">{value.startTime}</Moment>{" "}
                      </p>
                    </div>
                  )}
                  {props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ||
                  (props.transactionDetails.hideBuyButton === false &&
                    (!props.transactionDetails.expireTransactionData.length >
                      0 ||
                      (!props.transactionDetails.expireTransactionData.some(
                        (single) =>
                          single.product.sessionIds &&
                          single.product.sessionIds.includes(progammeSessionId)
                      ) &&
                        (!value.isFreeEvent ||
                          !props.transactionDetails.expireTransactionData.some(
                            (single) =>
                              single.product?.isSeasonPass === "true" &&
                              single.product?.isFreeEvent === "true"
                          ))))) ? (
                    <div className="wrap_div">
                      <img src={image} alt=""></img>
                      <div className="synopsis">
                        <strong>SYNOPSIS</strong>
                        <br />
                        <div className="synopsis_para">
                          {ReactHtmlParser(
                            synopsis
                              .split("<a")
                              .join('<a target="_blank"')
                              .replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                        </div>
                      </div>
                      <div className="button-group">
                        <div>
                          {value.isFreeEvent ? (
                            <div>
                              <Text as="h4" className="wrap_title">
                                {`This video is only available upon login with ${
                                  !endPremiumDate
                                    ? "any one of the following passes"
                                    : "an existing Premium All-Access / Basic Access Pass"
                                }.`}
                              </Text>
                              {props.productDetails.checkProductList.length >
                                0 && (
                                <div style={{ marginTop: "5vh" }}>
                                  <Button
                                    primary
                                    className="video_btn"
                                    onClick={(e) => {
                                      props.changeProductValues(
                                        "isFreeEvntClicked",
                                        true
                                      );
                                      getSingleDegitalPass(e);
                                    }}
                                  >
                                    Register Free Event
                                  </Button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <Text as="h4" className="wrap_title">
                                {`This video is only available upon login with ${
                                  !endPremiumDate
                                    ? "any one of the following passes"
                                    : "an existing Premium All-Access / Festival / Single Access Pass"
                                }.`}
                              </Text>
                              {props.productDetails.checkProductList.length >
                                0 && (
                                <div>
                                  {props.productDetails.singlePassList.length >
                                    0 && (
                                    <div>
                                      <Text as="h6" className="wrap_pass">
                                        Only purchasing this video?
                                      </Text>
                                      <Button
                                        primary
                                        className="video_btn"
                                        onClick={(e) => {
                                          props.changeProductValues(
                                            "isBundleEvent",
                                            false
                                          );
                                          getSingleDegitalPass(e);
                                        }}
                                      >
                                        Buy a Single Access Pass
                                      </Button>
                                    </div>
                                  )}
                                  {props.productDetails.bundlePassList.length >
                                    0 && (
                                    <div>
                                      <Text as="h6" className="wrap_pass">
                                        {eachValue.festivePromptText
                                          ? eachValue.festivePromptText
                                          : "Only purchasing festival video?"}
                                      </Text>
                                      <Button
                                        primary
                                        className="video_btn"
                                        onClick={(e) => {
                                          props.changeProductValues(
                                            "isBundleEvent",
                                            true
                                          );
                                          getSingleDegitalPass(e);
                                        }}
                                      >
                                        {eachValue.festiveBtnText
                                          ? eachValue.festiveBtnText
                                          : "Buy a Festival Pass"}
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          {value.isFreeEvent && (
                            <div>
                              <Text as="h6" className="wrap_pass">
                                Want access to all free videos?
                              </Text>
                              <Button
                                primary
                                className="video_btn"
                                onClick={(e) => {
                                  getDegitalPass(e, true);
                                }}
                              >
                                Get a 12M Basic Access Pass
                              </Button>
                            </div>
                          )}
                          {!endPremiumDate && (
                            <div>
                              <Text as="h6" className="wrap_pass">
                                Want access to all videos?
                              </Text>
                              <Button
                                primary
                                className="video_btn"
                                onClick={(e) => {
                                  getDegitalPass(e, false);
                                }}
                              >
                                Buy a 12M Premium All-Access Pass
                              </Button>
                            </div>
                          )}

                          {props.loginDetails.authenticated === null ||
                          props.loginDetails.authenticated === false ? (
                            <div>
                              <Text as="h6" className="wrap_pass">
                                Already have a pass?
                              </Text>
                              <Button
                                primary
                                className="video_btn"
                                onClick={(e) => {
                                  getDegitalPass(e, false);
                                }}
                              >
                                Click here to login
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="synopiscontainer">
                <div className="synopisdiv">
                  <div className="synopissection">
                    <Text as="p">
                      {value.writeUp &&
                        ReactHtmlParser(
                          value.writeUp
                            .split("<a")
                            .join('<a target="_blank"')
                            .replace(/(?:\r\n|\r|\n)/g, "<br />")
                        )}
                    </Text>
                  </div>
                  <div className="chatsection">
                    <div className="videoDetailFeature">
                      <Text as="h4">
                        <b>VIDEO HIGHLIGHTS</b>
                      </Text>
                      <Text as="p">
                        {value.featuredPresenters &&
                          ReactHtmlParser(
                            value.featuredPresenters
                              .split("<a")
                              .join('<a target="_blank"')
                              .replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                      </Text>
                      {value.bookletURL ? (
                        <a
                          href={value.bookletURL}
                          className="digital-booklet-link"
                          target="#"
                        >
                          <Button className="detail_btn">
                            View Digital Programme Booklet
                          </Button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="bookwebsection">
                  <div className="booksection">
                    {value.donationFlag && (
                      <a
                        href={"https://www.giving.sg/sso"}
                        target="_blank"
                        style={{
                          display: "flex",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src={Donation}
                          alt="icon"
                          className="donation-img"
                        />
                        <div className="giving-link">
                          <img
                            src={DonationLogo}
                            alt="icon"
                            className="donation-logo"
                          />
                          <Column className="booktext">
                            <Text as="p">
                              Support the SSO
                              <br />
                            </Text>
                            <Text as="p" className="donation-link">
                              giving.sg/sso
                            </Text>
                          </Column>
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    productDetails: state.Products,
    watchpartyDetails: state.WatchParty,
    overviewDetail: state.Overview,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked, sessionId, from) =>
    dispatch(
      transActions.fetchTransaction(data, buttonClicked, sessionId, from)
    ),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: (programmeId, passType) =>
    dispatch(prodActions.fetchProducts(programmeId, passType)),
  fetchCheckProducts: (programmeId, passType, isFreeEvent) =>
    dispatch(
      prodActions.fetchCheckProducts(programmeId, passType, isFreeEvent)
    ),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchDryRunProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchDryRunProgrammeDetail(id)),
  fetchChatProperties: () => dispatch(progrmmeActions.fetchChatProperties()),
  fetchShortCodeProperties: () =>
    dispatch(progrmmeActions.fetchShortCodeProperties()),
  changeWatchPartyValues: (f, e) =>
    dispatch(watchactions.changeWatchPartyValues(f, e)),
  checkoutAndJoinRoom: (user, invitation) =>
    dispatch(watchactions.checkoutAndJoinRoom(user, invitation)),
  changeUserActivityValues: (f, e) =>
    dispatch(userActions.changeUserActivityValues(f, e)),
  createUserLogs: (user, device, session, eventType) =>
    dispatch(userActions.createUserLogs(user, device, session, eventType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetail);
