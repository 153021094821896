import { api } from "./commonapi/apiPaths";
import { headers } from "./commonapi/Headers";
import { ifTokenExpire } from "./commonapi/TokenValidation";
import { changeLoginValues } from "./Login";
import { changeTransactionValues, fetchTransaction } from "./Transaction";

export const changeUserActivityValues = (field, value) => {
  return {
    type: "UPDATE_USERACTIVITY_VALUE",
    field,
    value,
  };
};

export const createUserLogs = (user, deviceInfo, session, eventType) => {
  var header = headers();
  const postPayload = {};
  postPayload.client = process.env.REACT_APP_CLIENTID;
  postPayload.user = {
    id: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  postPayload.device = deviceInfo;
  postPayload.session = session;
  postPayload.eventType = eventType;
  return (dispatch) => {
    if (header === "Token Expired") {
      return dispatch(changeLoginValues("tokenExpired", header));
    } else {
      return fetch(api.createUserActivity, {
        headers: header,
        method: "POST",
        body: JSON.stringify(postPayload),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          var tokenExiration = ifTokenExpire(data);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          }
        })
        .catch((err) => {
          var tokenExiration = ifTokenExpire(err);
          if (tokenExiration) {
            dispatch(changeLoginValues("tokenExpired", "Token Expired"));
          }
        });
    }
  };
};
