import React, { useEffect, useState } from "react";
import { Shell, Carousel, Card, Text } from "@StixNext/mojito-ui";
import { Column, Grid, Row, SearchInput, Dropdown } from "@StixNext/mojito-ui";
import * as Images from "./common/watch-free";
import * as actions from "../actions/VideoOnDemand";
import * as proActions from "../actions/Programmes";
import { connect } from "react-redux";
import { displayFilter, formatDate } from "../utils/utils";
import GreyOverlay from "../assets/images/grey_overlay.svg";

const VideoOnDemand = (props) => {
  let { videoOnDemandList, newReleasesList, videoOnDemandFullList } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filterValue, setFilterValue] = useState("");
  const [sortType, setSortType] = useState("1");
  const sortOptions = [
    { text: "Latest Release", value: "1" },
    { text: "Alphabetical Order (A - Z)", value: "2" },
    { text: "Alphabetical Order (Z - A)", value: "3" },
  ];

  const onSortChange = (option) => {
    setSortType(option);
  };

  const isOverlay = (slide) => {
    if (!props.loginDetails.authenticated) return false;
    else if (slide.isFreeEvent) return false;
    else if (
      props.loginDetails.authenticated &&
      (props.transactionDetails.hideBuyButton ||
        (props.transactionDetails.expireTransactionData.length > 0 &&
          props.transactionDetails.expireTransactionData.some(
            (single) =>
              single.product.sessionIds &&
              single.product.sessionIds.includes(slide.id)
          )))
    )
      return false;
    else return true;
  };

  const removeTags = (title) =>
    title.replace(/(<([^>]+)>)/gi, "").replace(/(?:&nbsp;|amp;)/g, "");

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  const onFilter = (e) => {
    setFilterValue(e.target.value);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleClick = (eventId = "FP2", type) => {
    const path = "playback";
    localStorage.setItem("pathDetail", JSON.stringify({ eventId, path }));
    props.history.push(`/watch/${eventId}`);
  };

  useEffect(() => {
    props.fetchPlayback();
    props.fetchProgrammes();
  }, []);

  const playbackType = (type) => {
    let playbackList = videoOnDemandFullList.filter(
      (item) => item.isNewRelease && item.isNewRelease == true
    );
    let liveStreamList = newReleasesList.filter(
      (item) => item.isNewRelease && item.isNewRelease == true
    );
    const newReleasesArray = [...playbackList, ...liveStreamList];
    const newVideoOnDemandList = videoOnDemandList.filter((item) =>
      item.isNewRelease ? item.isNewRelease == false : true
    );
    const requiredArray =
      type === "New Releases" ? newReleasesArray : newVideoOnDemandList;
    const sortField = sortType !== "1" ? "heading" : "displayStartTime";

    return (
      <>
        {requiredArray.length > 0 && (
          <>
            <Row className="line-in">
              <div className={"line-in-upcoming"} />
            </Row>
            <Row>
              <Text className="new_releases">
                {type == "New Releases" ? "Recently Added" : type}
              </Text>
            </Row>
          </>
        )}
        <Grid>
          <Row>
            {requiredArray.length > 0 &&
              requiredArray
                .filter((slide) => {
                  let title = slide.title
                    ? slide.title
                        .replace(/(<([^>]+)>)/gi, "")
                        .replace(/(?:&nbsp;|amp;)/g, "")
                    : "";
                  let writeUp = slide.writeUp
                    ? slide.writeUp
                        .replace(/(<([^>]+)>)/gi, "")
                        .replace(/(?:&nbsp;|amp;)/g, "")
                    : "";
                  let featuredPresenters = slide.featuredPresenters
                    ? slide.featuredPresenters
                        .replace(/(<([^>]+)>)/gi, "")
                        .replace(/(?:&nbsp;|amp;)/g, "")
                    : "";
                  let synopsis = slide.synopsis
                    ? slide.synopsis
                        .replace(/(<([^>]+)>)/gi, "")
                        .replace(/(?:&nbsp;|amp;)/g, "")
                    : "";

                  return filterValue
                    ? (title
                        .toUpperCase()
                        .includes(filterValue.toUpperCase()) ||
                        writeUp
                          .toUpperCase()
                          .includes(filterValue.toUpperCase()) ||
                        featuredPresenters
                          .toUpperCase()
                          .includes(filterValue.toUpperCase()) ||
                        synopsis
                          .toUpperCase()
                          .includes(filterValue.toUpperCase())) &&
                        (new Date() > new Date(slide.startTime) ||
                          type === "New Releases")
                    : new Date() > new Date(slide.startTime) ||
                        type === "New Releases";
                })
                .map((item) => {
                  item.heading = item.title.replace(/(<([^>]+)>)/gi, "");
                  return item;
                })
                .sort((a, b) =>
                  (sortType === "1"
                    ? new Date(b[sortField])
                    : sortType === "2"
                    ? removeTags(a[sortField])
                    : removeTags(b[sortField])) >
                  (sortType === "1"
                    ? new Date(a[sortField])
                    : sortType === "2"
                    ? removeTags(b[sortField])
                    : removeTags(a[sortField]))
                    ? 1
                    : -1
                )
                .map((slide, index) => {
                  let imageArray = slide.thumbNail
                    ? slide.thumbNail.split(",")
                    : "";
                  let image =
                    imageArray && imageArray.length > 1
                      ? imageArray[1]
                      : imageArray[0];

                  return (
                    <div className="contestant-tile" key={index}>
                      <Card
                        key={index}
                        className={"video-slides"}
                        onClick={() => handleClick(slide?.id)}
                        noPadding={false}
                        noBoxShadow={false}
                        noBorder={true}
                        noBorderRadius={true}
                      >
                        {isOverlay(slide) && (
                          <img
                            className="overlay_img"
                            src={GreyOverlay}
                            alt=""
                          />
                        )}
                        <img src={image} alt="" />
                        {!slide.isFreeEvent && (
                          <div
                            className={`premium-tag ${
                              isOverlay(slide) && "overlay_premium"
                            }`}
                          >
                            <div className="premium-tag-text">
                              <Text>Premium</Text>
                            </div>
                          </div>
                        )}
                        <div className="meta-details">
                          <div
                            className={"title-tiles"}
                            dangerouslySetInnerHTML={{ __html: slide?.title }}
                          />
                          <div>
                            <Text as="p" className="style-p">
                              {"Duration: " + slide?.duration + " mins"}{" "}
                            </Text>
                            <Text as="p" className="style-p">
                              {"Featuring: " + slide?.cardFeature}{" "}
                            </Text>
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
          </Row>
        </Grid>
      </>
    );
  };

  return (
    <Shell.Body className="playback-container">
      <div className="toggle-div">
        <div className="card-filter">
          <SearchInput placeholder="Keyword Search" onChange={onFilter} />
        </div>
        <div className="toggle-cover">
          <Grid>
            <Row className="sort_by_row">
              <Text as={"h5"} className="show-date">
                Sort By
              </Text>
              <Dropdown
                id="sort-dropdown"
                options={sortOptions}
                mulitple={false}
                Width={250}
                value={sortType}
                placeholder="Select"
                onChange={(item) => onSortChange(item.value)}
              />
            </Row>
          </Grid>
        </div>
      </div>
      {playbackType("New Releases")}
      <br />
      {playbackType("Playback")}
    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  videoOnDemandList: displayFilter(state.VideoOnDemand.videoOnDemandList),
  newReleasesList: state.Programmes.programmesList,
  videoOnDemandFullList: state.VideoOnDemand.videoOnDemandList,
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlayback: () => dispatch(actions.fetchPlayback()),
  fetchProgrammes: () => dispatch(proActions.fetchProgrammes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoOnDemand);
