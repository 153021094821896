import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Column,
  Text,
  Button,
  BouncerContextHook,
  SisticLivePlayerAlt,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";
import livestreamdot from "../assets/images/icons/livestream_dot.svg";
import Donation from "../assets/images/donation.png";
import DonationLogo from "../assets/images/donation-logo.png";
import Arrow from "../assets/images/leftarrow.png";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import * as userActions from "../actions/UserActivities";
import PrivateChat from "./PrivateChat";
import Iframe from "react-iframe";
import LikeBox from "./LikeBox";
import { formatDateTime, userDetails, numberToString } from "../utils/utils";
import StarIcon from "../assets/images/star_icon.svg";
import { pushEventToData } from "../utils/Datalayer";

function VipRoom(props) {
  const { history, location = {} } = props;
  const loginContext = BouncerContextHook();
  const [ChatOrQA, setChatOrQA] = useState("addon");
  const [play, setPlay] = useState(false);
  const [end, setEnd] = useState(false);
  const [start, setStart] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isEventPush, setIsEventPush] = useState(false);
  const [endPremiumDate, setEndPremiumDate] = useState(false);

  useEffect(() => {
    setIsSafari(
      window.navigator.userAgent.match(/OS X.*Safari/) &&
        !window.navigator.userAgent.match(/Chrome/)
    );
  }, []);

  useEffect(() => {
    var todayDate = moment(new Date(moment.utc().format()));
    var endPremiumDate = moment(
      new Date(moment.utc(props?.overviewDetail?.endPremiumPass).format())
    );
    setEndPremiumDate(endPremiumDate.diff(todayDate, "seconds") <= 0);
  }, [props.overviewDetail]);

  const registerUserLogs = async (eventType, otherEventInfo) => {
    let deviceDetail = await userDetails().then((data) => {
      return data;
    });
    props.createUserLogs(
      {
        ...props.loginDetails.user,
        sessionId: props.loginDetails.keycloak.sessionId,
      },
      deviceDetail,
      {
        ...props.programmeDetails.programme[0],
        eventDetailInfo: JSON.stringify({ ...otherEventInfo }, numberToString),
      },
      eventType
    );
  };

  let interval = null;

  function myFunction(startDateTime, duration) {
    interval = setInterval(function () {
      var serverDate = moment(new Date(moment.utc().format()));
      var indiaDate = moment(new Date(moment.utc(startDateTime).format()));
      setStart(true);
      if (indiaDate.diff(serverDate, "seconds") < 1) {
        if (!play) {
          setPlay(true);
          setStart(false);
        }
        clearInterval(interval);
      }
    }, 1000);
  }

  let getDegitalPass = async (e) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", "seasonpass");
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          "",
          ""
        );
      }
    }, 1000);
  };
  let getSingleDegitalPass = async (e) => {
    let progammeId = props.match.params.session;
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", "singlepass");
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          progammeId,
          ""
        );
      }
    }, 1000);
  };
  useEffect(() => {
    props.fetchChatProperties();
    props.fetchShortCodeProperties();
    let progammeId = props.match.params.session;
    if (process.env.REACT_APP_DRYRUN_PROGRAMME_IDS.includes(progammeId)) {
      props.fetchDryRunProgrammeDetail(progammeId);
    } else {
      props.fetchProgrammeDetail(progammeId);
    }
    props.fetchCheckProducts(progammeId, "singlePass");
    return () => {
      clearInterval(interval);
      props.changeProgrammesValues("programme", []);
    };
  }, []);

  useEffect(() => {
    if (props.productDetails.checkProductList.length > 0) {
      props.productDetails.checkProductList.forEach((expireProd) => {
        let expireInterval = null;
        let isFixedExpiryDate = !!expireProd.isFixedExpiryDate
          ? expireProd.isFixedExpiryDate
          : false;
        let validityInMonths = !!expireProd.validityInMonths
          ? parseInt(expireProd.validityInMonths)
          : 12;
        if (!isFixedExpiryDate) {
          let expdt = new Date();
          expireProd.expiresOn = new Date(
            expdt.setMonth(expdt.getMonth() + validityInMonths)
          ).toISOString();
        }
        let utcString = new Date(expireProd.expiresOn).toUTCString();
        expireInterval = setInterval(function () {
          var serverDate = moment(new Date(moment.utc().format()));
          var indiaDate = moment(new Date(moment.utc(utcString).format()));
          if (indiaDate.diff(serverDate, "seconds") < 1) {
            props.changeProductValues(
              "checkProductList",
              props.productDetails.checkProductList.filter(
                (item) => item.id !== expireProd.id
              )
            );
            clearInterval(expireInterval);
          }
        }, 1000);
      });
    }
  }, [props.productDetails.checkProductList]);
  useEffect(() => {
    if (props.programmeDetails.programme.length > 0) {
      let progammeId = props.match.params.id;
      props.fetchCheckProducts(
        progammeId,
        "singlePass",
        props.programmeDetails.programme[0].isFreeEvent
      );
      myFunction(
        props.programmeDetails.programme[0].startTime,
        props.programmeDetails.programme[0].duration
      );
      if (
        props.programmeDetails.programme[0].ChatFlag &&
        props.programmeDetails.programme[0].QAFlag
      ) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].ChatFlag) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].QAFlag) {
        setChatOrQA("QA");
      }
    }
  }, [props.programmeDetails.programme]);

  useEffect(() => {
    if (!props.watchpartyDetails.isValidInvitation) {
      history.push("/");
    }
  }, [props.watchpartyDetails.isValidInvitation]);

  const getSynopsis = (writeUp) => {
    let regex = /.*SYNOPSIS.*?(?=\<br>)<br>(?<synopsis>.+?(?=\<br>|&))/;
    let { groups } = writeUp.match(regex);
    return groups.synopsis;
  };

  useEffect(() => {
    if (
      props?.programmeDetails?.programme?.length > 0 &&
      props?.loginDetails?.authenticated &&
      (props.transactionDetails.hideBuyButton ||
        (props.transactionDetails.expireTransactionData.length > 0 &&
          props.transactionDetails.expireTransactionData.some(
            (single) =>
              single.product.sessionIds &&
              single.product.sessionIds.includes(
                props?.programmeDetails?.programme[0]?.id
              )
          ))) &&
      play &&
      !start &&
      !isEventPush
    ) {
      pushEventToData("stream_watched", [
        {
          item_id: props?.programmeDetails?.programme[0]?.id,
          item_name: props?.programmeDetails?.programme[0]?.title
            ?.replace(/(<([^>]+)>)/gi, "")
            ?.replace(/(?:&nbsp;|amp;)/g, ""),
          start_time: moment(new Date())
            .tz("Asia/Singapore")
            .format("DD MMM YYYY hh:mm:ss a"),
        },
      ]);
      setIsEventPush(true);
    }
  }, [
    props.programmeDetails.programme,
    props.loginDetails.authenticated,
    props.transactionDetails,
    play,
    start,
    isEventPush,
  ]);

  return (
    <>
      {props.programmeDetails.programme.length > 0 &&
        props.programmeDetails.programme.map((eachValue, key) => {
          const inPreviewMode = localStorage.getItem("inPreviewMode");
          const value =
            inPreviewMode === "true" && eachValue.preview
              ? eachValue.preview
              : eachValue;
          let progammeSessionId = props.match.params.session;
          let imageArray = value.thumbNail ? value.thumbNail.split(",") : "";
          let image =
            imageArray && imageArray.length > 1 ? imageArray[1] : imageArray[0];
          let synopsis = value.synopsis ?? getSynopsis(value.writeUp);
          return (
            <div
              className={`row vodContainer adjustment vip-room ${
                isSafari ? "safari" : ""
              }`}
            >
              <div className="container">
                <div className="vip_room">
                  <div className="vip_room_link">
                    <div className="title title-nav">
                      <Link to={`/watch/${progammeSessionId}`}>
                        <img src={Arrow} alt="icon" />
                      </Link>
                      <Text as="captionblock" className="titletext">
                        <div
                          className="livestream-dot"
                          style={{
                            fontSize: "14px",
                            display: "flex",
                            flexFlow: "row",
                            alignItems: "center",
                            columnGap: "6px",
                          }}
                        >
                          Live stream <img src={livestreamdot} alt="icon" />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{ __html: value.title }}
                        />
                        <div style={{ fontSize: "13px", fontWeight: "100" }}>
                          {`${formatDateTime(value.startTime)} @ SSOLOUNGE`}
                        </div>
                      </Text>
                    </div>
                  </div>
                  <div className="vip_room_batch">
                    <div className="vip_room_star">
                      <img src={StarIcon} />
                    </div>
                    <div className="vip_batch_text">
                      <Text>You are in a VIP Room</Text>
                    </div>
                  </div>
                </div>
                <div className={`wholediv ${isSafari ? "safari" : ""}`}>
                  {props.loginDetails.authenticated &&
                    (props.transactionDetails.hideBuyButton ||
                      (props.transactionDetails.expireTransactionData.length >
                        0 &&
                        props.transactionDetails.expireTransactionData.some(
                          (single) =>
                            single.product.sessionIds &&
                            single.product.sessionIds.includes(
                              progammeSessionId
                            )
                        ))) && (
                      <div className="videodiv disablechat">
                        {value?.turnOnStaticBanner && value?.staticBanner ? (
                          <img
                            src={value?.staticBanner}
                            width="100%"
                            height={"100%"}
                          />
                        ) : (
                          <SisticLivePlayerAlt
                            bgColor="white"
                            color="black"
                            className="video-player"
                            playerItems={{
                              src: value.videoURL,
                              stopChat: value.chatFlag
                                ? !value.chatFlag
                                : true || value.id == "FPPROGRAMMETEST",
                              chatContent: (
                                <div>
                                  <Iframe
                                    url={
                                      process.env.PUBLIC_URL +
                                      `/chat.html?sessionId=${
                                        value?.chatSessionId
                                      }&firstname=${
                                        props.loginDetails.user.firstname
                                      }&email=${
                                        props.loginDetails.user.email
                                      }&userid=${
                                        props.loginDetails.user.userid
                                      }&sessioncode=${
                                        value.sessionCode
                                      }&username=${btoa(
                                        props.loginDetails.user.username
                                      )}`
                                    }
                                    width="100%"
                                    height="413px"
                                    id="myId"
                                    className="pigeonhole-iframe"
                                    display="initial"
                                    position="relative"
                                  />
                                </div>
                              ),
                              enableChat: value.chatFlag,
                              reactionContent: (
                                <div className="in-player-likes">
                                  <LikeBox
                                    slide={value}
                                    session={props.match.params.session}
                                  />
                                </div>
                              ),
                              registerUserLogs,
                            }}
                          />
                        )}
                      </div>
                    )}
                  {!play && start && (
                    <div
                      className={`text_div ${
                        value.ChatFlag || value.QAFlag ? "" : "disablechat"
                      }`}
                    >
                      <img src={image}></img>
                      <p>
                        Join us from
                        <br />
                        <Moment format="hh:mma">
                          {value.startTime}
                        </Moment> SGT,{" "}
                        <Moment format="DD MMM YYYY">{value.startTime}</Moment>{" "}
                      </p>
                    </div>
                  )}
                  {props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ||
                  (props.transactionDetails.hideBuyButton === false &&
                    (!props.transactionDetails.expireTransactionData.length >
                      0 ||
                      !props.transactionDetails.expireTransactionData.some(
                        (single) =>
                          single.product.sessionIds &&
                          single.product.sessionIds.includes(progammeSessionId)
                      ))) ? (
                    <div className="wrap_div">
                      <img src={image} alt=""></img>
                      <div className="synopsis">
                        <strong>SYNOPSIS</strong>
                        <br />
                        <div className="synopsis_para">
                          {ReactHtmlParser(
                            synopsis.replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                        </div>
                      </div>
                      <div className="button-group">
                        <div>
                          <Text as="h4" className="wrap_title">
                            {`This video is only available upon login with ${
                              !endPremiumDate
                                ? "any one of the following passes"
                                : "an existing Premium All-Access / Festival / Single Access Pass"
                            }.`}
                          </Text>
                          {props.productDetails.checkProductList.length > 0 && (
                            <div>
                              {props.productDetails.singlePassList.length >
                                0 && (
                                <div>
                                  <Text as="h6" className="wrap_pass">
                                    Only purchasing this video?
                                  </Text>
                                  <Button
                                    primary
                                    className="video_btn"
                                    onClick={(e) => {
                                      props.changeProductValues(
                                        "isBundleEvent",
                                        false
                                      );
                                      getSingleDegitalPass(e);
                                    }}
                                  >
                                    Buy a Single Access Pass
                                  </Button>
                                </div>
                              )}
                              {props.productDetails.bundlePassList.length >
                                0 && (
                                <div>
                                  <Text as="h6" className="wrap_pass">
                                    {eachValue.festivePromptText
                                      ? eachValue.festivePromptText
                                      : "Only purchasing festival video?"}
                                  </Text>
                                  <Button
                                    primary
                                    className="video_btn"
                                    onClick={(e) => {
                                      props.changeProductValues(
                                        "isBundleEvent",
                                        true
                                      );
                                      getSingleDegitalPass(e);
                                    }}
                                  >
                                    {eachValue.festiveBtnText
                                      ? eachValue.festiveBtnText
                                      : "Buy a Festival Pass"}
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}
                          {!endPremiumDate && (
                            <div>
                              <Text as="h6" className="wrap_pass">
                                Want access to all videos?
                              </Text>
                              <Button
                                primary
                                className="video_btn"
                                onClick={(e) => {
                                  getDegitalPass(e);
                                }}
                              >
                                Buy a 12M Premium All-Access Pass
                              </Button>
                            </div>
                          )}
                          {props.loginDetails.authenticated === null ||
                          props.loginDetails.authenticated === false ? (
                            <div>
                              <Text as="h6" className="wrap_pass">
                                Already have a pass?
                              </Text>
                              <Button
                                primary
                                className="video_btn"
                                onClick={(e) => {
                                  getDegitalPass(e);
                                }}
                              >
                                Click here to login
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="synopiscontainer">
                <div className="synopisdiv">
                  <div className="synopissection">
                    <Text as="p">
                      {value.writeUp &&
                        ReactHtmlParser(
                          value.writeUp.replace(/(?:\r\n|\r|\n)/g, "<br />")
                        )}
                    </Text>
                  </div>
                  <div className="chatsection">
                    <div className="videoDetailFeature">
                      <Text as="h4">
                        <b>VIDEO HIGHLIGHTS</b>
                      </Text>
                      <Text as="p">
                        {value.featuredPresenters &&
                          ReactHtmlParser(
                            value.featuredPresenters.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br />"
                            )
                          )}
                      </Text>
                      {value.bookletURL ? (
                        <a
                          href={value.bookletURL}
                          className="digital-booklet-link"
                          target="#"
                        >
                          <Button className="detail_btn">
                            View Digital Programme Booklet
                          </Button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="bookwebsection">
                  <div className="booksection">
                    {value.donationFlag && (
                      <a
                        href={"https://www.giving.sg/sso"}
                        target="_blank"
                        style={{
                          display: "flex",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src={Donation}
                          alt="icon"
                          className="donation-img"
                        />
                        <div className="giving-link">
                          <img
                            src={DonationLogo}
                            alt="icon"
                            className="donation-logo"
                          />
                          <Column className="booktext">
                            <Text as="p">
                              Support the SSO
                              <br />
                            </Text>
                            <Text as="p" className="donation-link">
                              giving.sg/sso
                            </Text>
                          </Column>
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    productDetails: state.Products,
    watchpartyDetails: state.WatchParty,
    overviewDetail: state.Overview,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked, sessionId, from) =>
    dispatch(
      transActions.fetchTransaction(data, buttonClicked, sessionId, from)
    ),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: (programmeId, passType) =>
    dispatch(prodActions.fetchProducts(programmeId, passType)),
  fetchCheckProducts: (programmeId, passType) =>
    dispatch(prodActions.fetchCheckProducts(programmeId, passType)),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchDryRunProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchDryRunProgrammeDetail(id)),
  fetchChatProperties: () => dispatch(progrmmeActions.fetchChatProperties()),
  fetchShortCodeProperties: () =>
    dispatch(progrmmeActions.fetchShortCodeProperties()),
  createUserLogs: (user, device, session, eventType) =>
    dispatch(userActions.createUserLogs(user, device, session, eventType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VipRoom);
