import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { deviceDetect } from "react-device-detect";
import queryString from "query-string";
import ScrollTop from "./ScrollTop";
import Overview from "./components/Overview";
import Schedule from "./components/Schedule";
import Upcomming from "./components/upcomming";
import Livestream from "./components/Livestream";
import VideoOnDemand from "./components/VideoOnDemand";
import { watchfree } from "./components/watchFree";
import { FooterContent } from "./components/FooterUtil";
import AboutSso from "./components/AboutSso";
import PrivateRoute from "./components/common/PrivateRoute";

import {
  Shell,
  BouncerContextProvider,
  BouncerContextHook,
} from "@StixNext/mojito-ui";
import Header from "./components/common/Header";
import * as actions from "./actions/Login";
import Keycloak from "keycloak-js";
import { SessionEndModal } from "./components/SessionEndModal";
import { useKeycloak } from "@react-keycloak/web";
import { useGlobalWorker } from "./worker";
import { getUserSession } from "./firebase/utils";
import VipRoom from "./components/VipRoom";
import FixedPath from "./components/FixedPath";
import MyAccount from "./components/common/MyAccount/MyAccount";

const Routes = (props) => {
  const { history, location = {}, session, match } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const keycloakJs = Keycloak(process.env.REACT_APP_BOUNCERCONFIG_URL);
  const { session_worker, multipleSession } = useGlobalWorker(session);

  let updateUser = (value) => {
    props.changeLoginValues("user", value);
  };
  const [keycloak, initialized] = useKeycloak();
  const valueBouncer = BouncerContextHook();

  useEffect(() => {
    props.changeLoginValues("history", history);
    props.changeLoginValues("location", location);
  }, []);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        localStorage.setItem("isPageReload", true);
      }
    }
  });

  useEffect(() => {
    if (location.search.includes("?inPreviewMode=true")) {
      localStorage.setItem("inPreviewMode", "true");
    } else {
      localStorage.setItem("inPreviewMode", "false");
    }
    if (
      !(
        /\watch\/[a-zA-Z]|[0-9]|[&._-]/.test(location.pathname) ||
        /\playback\/[a-zA-Z]|[0-9]|[&._-]/.test(location.pathname) ||
        /\upcoming\/[a-zA-Z]|[0-9]|[&._-]/.test(location.pathname)
      )
    ) {
      localStorage.setItem("prevPath", location.pathname);
    }

    if (!location.pathname.includes("/grouppass")) {
      localStorage.removeItem("grouppassid");
      localStorage.removeItem("grouppass");
      localStorage.removeItem("grouppasslogin");
      localStorage.removeItem("grouppasscompleted");
    }
  }, [location.pathname]);

  const value = { user: { ...props.loginDetails.user }, updateUser };
  let finalKeycloak = initialized ? keycloak : keycloakJs;

  useEffect(() => {
    if (props.loginDetails.authenticated) {
      let sessionId = props.loginDetails.keycloak.sessionId;
      let userId = props.loginDetails.user.userid;
      session_worker.startSession(userId, sessionId);
    }
  }, [props.loginDetails.authenticated]);

  useEffect(() => {
    if (props.loginDetails.authenticated) {
      const { keycloak, user } = props.loginDetails;
      const { userid, email } = user;
      const { sessionId } = keycloak;

      const timestamp = +new Date();
      const useragent = deviceDetect();

      const userSession = {
        userid,
        email,
        sessionId,
        timestamp,
        useragent,
      };

      try {
        getUserSession.push(userSession);
      } catch (e) {
        console.log(e);
      }
    }
  }, [props.loginDetails.authenticated]);

  useEffect(() => {
    if (multipleSession) {
      setModalOpen(true);
      setTimeout(() => props.loginDetails.keycloak.logout(), 5000);
    }
  }, [multipleSession]);

  const closeModal = () => {
    setModalOpen(false);
  };
  if (!initialized) {
    return <div>Loading...</div>;
    //   <div className="App">
    //   <div className="Loadercenter">
    //     <div className="loaderContent">
    //       <div className="loaderImg"></div>
    //       {/* <img src={main1} className="loaderImg"/> */}
    //       <div class="spinner">
    //         <span className="LoadingText">Loading</span>
    //         <div class="bounce1"></div>
    //         <div class="bounce2"></div>
    //         <div class="bounce3"></div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  }

  const isDetailPage = () => {
    return location.pathname.includes("/playback/" || "/upcoming/" || "/watch/")
      ? "no-nav"
      : "";
  };

  return (
    <Fragment>
      <SessionEndModal isOpen={modalOpen} onClose={closeModal} />
      <ScrollTop />
      <BouncerContextProvider keycloakJson={finalKeycloak} loginRequired={true}>
        <Shell value={value}>
          <Header history={history} location={location} />
          <Switch>
            <Route exact path="/" component={Overview} />
            <Route exact path="/overview" component={Overview} />
            <Route exact path="/subscribe/overview" component={Overview} />
            <Route
              exact
              path="/grouppass"
              render={(props) => {
                let grouppassid = queryString.parse(location.search).id;
                if (grouppassid) {
                  localStorage.setItem("grouppassid", grouppassid);
                  localStorage.setItem("grouppass", true);
                  localStorage.setItem("grouppasslogin", true);
                }
                return <Overview />;
              }}
            />
            <Route
              exact
              path="/grouppass/:id"
              render={(props) => {
                let grouppassid = props.match.params.id;
                if (grouppassid) {
                  localStorage.setItem("grouppassid", grouppassid);
                  localStorage.setItem("grouppass", true);
                  localStorage.setItem("grouppasslogin", true);
                }
                return <Overview />;
              }}
            />
            <Route
              exact
              path="/discount/:productid/:code"
              render={(props) => {
                let discountProductId = props.match.params.productid;
                let discountCode = props.match.params.code;
                if (discountProductId && discountCode) {
                  localStorage.setItem("discountProductId", discountProductId);
                  localStorage.setItem("discountCode", discountCode);
                  localStorage.setItem("discountPassRedemption", true);
                }
                return <Overview />;
              }}
            />
            <Route exact path="/schedule" component={Schedule} />
            <Route exact path="/upcoming" component={Upcomming} />
            <Route exact path="/watch" component={Livestream} />
            <Route exact path="/playback" component={VideoOnDemand} />
            <Route exact path="/about" component={AboutSso} />
            <Route exact path="/watch/:id" component={FixedPath} />
            <Route exact path="/watchforfree" component={watchfree} />
            <PrivateRoute path="/myaccount" component={MyAccount} />
            <Route exact path="/watch/:session/:room_id" component={VipRoom} />
            <Route path="*" render={() => <Redirect to="/overview" />} />
          </Switch>
          {/* <FooterBanner/> */}
          <Shell.Footer
            className={`${isDetailPage()} appFooter`}
            footerItems={{ FooterComponent: FooterContent }}
          />
          {/* <Shell.Footer /> */}
        </Shell>
      </BouncerContextProvider>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
