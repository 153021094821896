import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Dropdown } from "@StixNext/mojito-ui";

function AccountDetails(props) {
  const [details, setDetails] = useState({
    e_mail: "john.doe@gmail.com",
    first_name: "John",
    last_name: "Doe",
    mobile: "91234567",
    yob: "1975",
    nationality: "Singapore",
    country_of_residence: "Singapore",
  });
  let { e_mail, first_name, last_name, mobile } = details;

  const [countries, setCountries] = useState([
    { text: "India", value: "India" },
    { text: "Singapore", value: "Singapore" },
  ]);

  // To years list
  const year = new Date().getFullYear();
  const years = Array.from(new Array(333), (val, index) => {
    return { text: year - index, value: year - index };
  });
  // To years list

  // useEffect(() => {
  //   fetch(`http://api.first.org/data/v1/countries`)
  //     .then((res) => res.json())
  //     .then((value) => {
  //       setCountries(value);
  //     })
  //     .catch((err) => err);
  // }, []);

  return (
    <>
      <div className="acc-details AnotherStyleClassForm">
        <div className="head">
          <label>View My Details</label>
        </div>
        <div className="acc-row">
          <div className="acc-fields">
            <label>*Email Address</label>
            <br />
              <input className="InputBoxNumber" type="text" disabled value={props.loginDetails.user?.email} />
          </div>

        </div>
        <div className="acc-row">
          <div className="acc-fields">
            <label>*First Name</label>
            <br />
            <input type="text" className="InputBoxNumber" disabled value={props.loginDetails.user?.firstname} />
          </div>
          <div className="acc-fields">
            <label>*Last Name</label>
            <br />
            <input type="text" className="InputBoxNumber" disabled value={props.loginDetails.user?.lastname} />
          </div>
        </div>


        <div className="acc-btn  AnotherStyleClass">
         <a className="EditButton-LinkStyle" href="https://ticketing.sistic.com.sg/sistic/patron/management" target={"_blank"}><Button primary className="update-btn">
            Edit Details
          </Button></a>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
