import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text, Button, DropInPayment, RadioButton } from "@StixNext/mojito-ui";

import passimage from "../../assets/images/pass_img.jpg";
import loading from "../../assets/images/loading.gif";
import Zoom from "react-reveal/Zoom";
import { theme } from "./../../Theme";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import * as prodActions from "../../actions/Products";
import Moment from "react-moment";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import crypto from "crypto";
import { pushEventToData } from "../../utils/Datalayer";

function BuySingle(props) {
  const [open, setOpen] = useState(true);
  const [selectedId, setSelectedID] = useState();
  const [endPremiumDate, setEndPremiumDate] = useState(false);

  useEffect(() => {
    // let programmeId = props.match.params.id;
    props.changeProductValues("selectedProduct", {});
    props.changeTransactionValues("productPrice", 0);
    props.fetchProducts(
      props.productDetails,
      "singlePass",
      props.productDetails.isFreeEvntClicked
    );

    return () => {
      props.changeProductValues("productList", []);
    };
  }, []);

  useEffect(() => {
    var todayDate = moment(new Date(moment.utc().format()));
    var endPremiumDate = moment(
      new Date(moment.utc(props?.overviewDetail?.endPremiumPass).format())
    );
    setEndPremiumDate(endPremiumDate.diff(todayDate, "seconds") <= 0);
  }, [props.overviewDetail]);

  const isFreeEvent = props.productDetails.isFreeEvntClicked;
  const freeProducts = props.productDetails.productList.filter(
    (product) => product.isFreeEvent === true
  );

  const buyProduct = () => {
    if (props.productDetails.selectedProduct.isDiscountProduct) {
      let selectedProduct = props.productDetails.selectedProduct;
      props.discountPasscodeValidation(
        selectedProduct.id,
        props.productDetails.enteredPasscode
      );
    } else {
      pushEventToData("addtoCart", [
        {
          item_id: props?.productDetails?.selectedProduct?.id,
          item_name: props?.productDetails?.selectedProduct?.title
            ?.replace(/(<([^>]+)>)/gi, "")
            ?.replace(/(?:&nbsp;|amp;)/g, ""),
          currency: "SGD",
          price: props?.productDetails?.selectedProduct?.price,
          quantity: 1,
        },
      ]);
      props.getMerchantAuthToken(props.transactionDetails.productPrice);
      props.changeTransactionValues("loader", "block");
      props.changeTransactionValues("paymentLoader", "none");
      props.changeTransactionValues("transactionStatus", 2);
    }
  };

  useEffect(() => {
    if (props.productDetails.isValidPasscode) {
      pushEventToData("addtoCart", [
        {
          item_id: props?.productDetails?.selectedProduct?.id,
          item_name: props?.productDetails?.selectedProduct?.title
            ?.replace(/(<([^>]+)>)/gi, "")
            ?.replace(/(?:&nbsp;|amp;)/g, ""),
          currency: "SGD",
          price: props?.productDetails?.selectedProduct?.price,
          quantity: 1,
        },
      ]);
      props.changeProductValues("isValidPasscode", "");
      props.changeProductValues("passcodeValidationMessage", "");
      props.getMerchantAuthToken(props.transactionDetails.productPrice);
      props.changeTransactionValues("loader", "block");
      props.changeTransactionValues("paymentLoader", "none");
      props.changeTransactionValues("transactionStatus", 2);
    }
  }, [props.productDetails.isValidPasscode]);

  const selectedIdFunction = (value) => {
    setSelectedID(value);
  };
  return (
    <>
      {/* {isFreeEvent && freeProducts.length === 0 ? (
        <h1 style={{ textAlign: "center" }}>
          This video is not tagged to any free bundle event
        </h1>
      ) : (
        <> */}
      <div
        style={{
          padding: "10px 0px",
        }}
        className={open ? "openCon" : "closeCon"}
      >
        <Grid>
          <Row>
            <Column xl={5} xs={12}>
              <img className="main_image" src={passimage} alt="icon" />
            </Column>
            <Column
              xl={7}
              xs={12}
              className={"payment-section-text"}
              style={{ flexFlow: "column", alignItems: "flex-start" }}
            >
              <Row>
                <Text as="h4" className="pass_head">
                  Please select your Pass
                </Text>
              </Row>
              <div
                className={
                  props.productDetails.productLoading
                    ? "loader-product-show"
                    : "loader-pro"
                }
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "190px", height: "auto" }}
                    src={loading}
                    alt="Avatar"
                  />
                </div>
              </div>
              {props.productDetails.productList &&
                props.productDetails.productList.length > 0 &&
                !props.productDetails.productLoading &&
                props.productDetails.productList.map((o, k) => {
                  if (
                    (isFreeEvent && o.isFreeEvent) ||
                    (!isFreeEvent && !o.isFreeEvent)
                  ) {
                    if (
                      k === 0 &&
                      ((Object.keys(props.productDetails.selectedProduct)
                        .length > 0 &&
                        props.productDetails.selectedProduct.id !== o.id) ||
                        !Object.keys(props.productDetails.selectedProduct)
                          .length > 0) &&
                      !props.productDetails.productList.some(
                        (pass) =>
                          pass.id === props.productDetails.selectedProduct.id
                      ) &&
                      !o.isSeasonPass
                    ) {
                      let filterProduct =
                        props.productDetails.productList.filter(function (
                          item
                        ) {
                          return item.id === o.id;
                        })[0];
                      props.changeProductValues(
                        "selectedProduct",
                        filterProduct
                      );
                      props.changeProductValues(
                        "isSubscriableProduct",
                        filterProduct.isSubscribableProduct
                      );
                      props.changeTransactionValues("productPrice", o.price);
                    }
                    return (
                      <>
                        <Row className="product_section" key={k}>
                          <Column xl={9}>
                            <RadioButton
                              className="season_radio"
                              labelText={o.title}
                              value={o.price}
                              name="radio"
                              id={o.id}
                              checked={k === 0 && true}
                              onChange={(e) => {
                                selectedIdFunction(o?.id);
                                props.changeProductValues(
                                  "enteredPasscode",
                                  ""
                                );
                                props.changeProductValues(
                                  "isValidPasscode",
                                  ""
                                );
                                o?.isDiscountProduct
                                  ? setOpen(true)
                                  : setOpen(false);

                                var selectedProduct =
                                  props.productDetails.productList.filter(
                                    function (item) {
                                      return item.id === e.target.id;
                                    }
                                  );
                                props.changeProductValues(
                                  "selectedProduct",
                                  selectedProduct[0]
                                );
                                props.changeProductValues(
                                  "isSubscriableProduct",
                                  selectedProduct[0].isSubscribableProduct
                                );
                                props.changeTransactionValues(
                                  "productPrice",
                                  e.target.value
                                );
                              }}
                            >
                              <span className="info_section">
                                Pass is valid until{" "}
                                <Moment format="DD MMM YYYY HH:mm">
                                  {o.expiresOn}
                                </Moment>
                              </span>
                              {o.description ? (
                                <span className="info_section">
                                  {o.isFreeEvent ? (
                                    ReactHtmlParser(
                                      o.description
                                        .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                        .replace(/(?:<p>|<\/p>)/g, "")
                                    )
                                  ) : (
                                    <span>
                                      {ReactHtmlParser(
                                        o.description
                                          .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                          .replace(/(?:<p>|<\/p>)/g, "")
                                      )}
                                    </span>
                                  )}
                                </span>
                              ) : null}
                            </RadioButton>
                          </Column>
                          <Column
                            xl={2}
                            sm={3}
                            className={
                              o.isFreeEvent
                                ? "pass_price free_event"
                                : "pass_price"
                            }
                          >
                            <Text as="h6">
                              <strong>
                                {o.isFreeEvent ? "Free Event" : `S$${o.price}`}
                              </strong>
                            </Text>
                          </Column>
                        </Row>{" "}
                        <div>
                          {o?.isDiscountProduct && selectedId && (
                            <Row
                              className={
                                open ? "openedStyle promo_row " : "closedStyle"
                              }
                            >
                              <Column
                                className={"promo_col transitionS"}
                                xl={9}
                                md={7}
                                sm={7}
                              >
                                <div>
                                  <Row className="promo_title">
                                    PROMO PASSWORD
                                  </Row>
                                  <Row>
                                    <input
                                      className={
                                        props.productDetails.isValidPasscode !==
                                          "" &&
                                        props.productDetails.isValidPasscode ===
                                          false
                                          ? "promo_input_Error"
                                          : "promo_input"
                                      }
                                      type="password"
                                      onChange={(e) => {
                                        props.changeProductValues(
                                          "enteredPasscode",
                                          crypto
                                            .createHash("md5")
                                            .update(e.target.value)
                                            .digest("hex")
                                        );
                                      }}
                                    />
                                  </Row>
                                  {props.productDetails.isValidPasscode !==
                                    "" &&
                                    !props.productDetails.isValidPasscode && (
                                      <p className="error_promo_p">
                                        {props?.productDetails
                                          ?.passcodeValidationMessage
                                          ? props?.productDetails
                                              ?.passcodeValidationMessage
                                          : "Invalid Promo Password Entered"}
                                      </p>
                                    )}
                                </div>
                              </Column>
                            </Row>
                          )}{" "}
                        </div>{" "}
                      </>
                    );
                  }
                })}
              {isFreeEvent && freeProducts.length < 1 && (
                <Row className="no_prod_row">
                  <Text as="h4" className="no_product_head">
                    This video is not tagged to any single free bundle event
                  </Text>
                </Row>
              )}
              <Row>
                <Column xl={1}>
                  <div></div>
                </Column>
                <Column xl={11} className="redirect_pass_section">
                  <div>
                    {isFreeEvent && (
                      <span
                        className="gain_free_access_span"
                        onClick={() => {
                          props.changeTransactionValues(
                            "transactionStatus",
                            12
                          );
                          props.changeProductValues("isFreeEvntClicked", true);
                          localStorage.setItem("pass_type", "seasonfreepass");
                        }}
                      >
                        Watching more free videos? Register for a<br />
                        <span className="red_font">12M Basic Access Pass!</span>
                      </span>
                    )}
                    {!endPremiumDate && (
                      <span
                        className="gain_access_span"
                        onClick={() => {
                          props.changeTransactionValues("transactionStatus", 9);
                          props.changeProductValues("isFreeEvntClicked", false);
                          localStorage.setItem("pass_type", "seasonpass");
                        }}
                      >
                        Or gain access to all videos by getting a<br />
                        <span className="red_font">
                          12M Premium All-Access Pass!
                        </span>
                      </span>
                    )}
                  </div>
                </Column>
              </Row>
              {props.productDetails.productList &&
                props.productDetails.productList.length > 0 && (
                  <Row style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button
                      className="buy_btn"
                      primary
                      disabled={
                        props.transactionDetails.productPrice ? false : true
                      }
                      onClick={(e) => {
                        buyProduct();
                      }}
                    >
                      {isFreeEvent ||
                      (Object.keys(props.productDetails.selectedProduct)
                        .length > 0 &&
                        props.productDetails.selectedProduct.isFreeEvent)
                        ? "Register Now"
                        : props.productDetails.isSubscriableProduct
                        ? "Subscribe Now"
                        : "Buy Now"}
                    </Button>
                  </Row>
                )}
            </Column>
          </Row>
        </Grid>
      </div>
    </>
    // )
    // }
    // </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
  overviewDetail: state.Overview,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  getMerchantAuthToken: (amount) =>
    dispatch(actions.getMerchantAuthToken(amount)),
  fetchTransaction: (data, clicked, sessionId) =>
    dispatch(transActions.fetchTransaction(data, clicked, sessionId)),
  fetchProducts: (programmeId, passType, freeEventClciked) =>
    dispatch(
      prodActions.fetchProducts(programmeId, passType, freeEventClciked)
    ),
  discountPasscodeValidation: (productid, passcode) =>
    dispatch(prodActions.discountPasscodeValidation(productid, passcode)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuySingle);
