import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Shell,
  Stage,
  FeatureArtists,
  Text,
  Button,
  BouncerContextHook,
} from "@StixNext/mojito-ui";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import moment from "moment";

import useCommonI18n from "../utils/i18nHelpers";
import main from "../assets/images/stage.png";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as overviewActions from "../actions/Overview";
import * as billingActions from "../actions/Billing";

function Overview(props) {
  const { translate } = useCommonI18n();
  const value = BouncerContextHook();
  const [isSubscribeRoute, setIsSubscribeRoute] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState([]);

  let getSubscribableProducts = async () => {
    props.fetchProducts({}, "", false);
    localStorage.setItem("subscribeButtonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", "subscription_pass");
    let seasonPass = await props.productDetails.productList.filter((item) => {
      return (
        !!item.isSeasonPass &&
        item.isSeasonPass === true &&
        ((item.isFreeEvent && item.isFreeEvent === "false") ||
          !item.isFreeEvent) &&
        !!item.isSubscribableProduct &&
        item.isSubscribableProduct === true &&
        item.isActive === true &&
        parseInt(item.price) > 0
      );
    });
    if (!props.loginDetails.authenticated) {
      if (seasonPass.length === 1) {
        localStorage.setItem("productId", seasonPass[0].id);
      }
      value.loginKeycloak();
    } else {
      if (seasonPass.length > 1) {
        props.changeTransactionValues("transactionStatus", 13);
        props.changeTransactionValues("isOpen", true);
      } else if (seasonPass.length === 1) {
        localStorage.setItem("productId", seasonPass[0].id);
        props.getMerchantAuthToken(seasonPass[0].price);
        props.changeProductValues("isSubscriableProduct", true);
        props.changeTransactionValues("productPrice", seasonPass[0].price);
        props.changeProductValues("selectedProduct", seasonPass[0]);

        props.changeProductValues("isFreeEvntClicked", false);

        props.subscribeProduct(props.loginDetails.user, seasonPass[0]);
        props.changeTransactionValues("transactionStatus", 2);
        props.changeTransactionValues("isOpen", true);
      }
    }
  };

  let getDegitalPass = async (isFree = false, isSubscribeButton = false) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("isSubscribeButton", isSubscribeButton);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", isFree ? "seasonfreepass" : "seasonpass");
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        value.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          "",
          ""
        );
      }
    }, 1000);
  };

  let buttonText = translate("overview.stage.buttonText");
  let buttonOnClick = getDegitalPass;
  if (isSubscribeRoute) {
    buttonText = translate("subscribe.overview.stage.buttonText");
    buttonOnClick = getSubscribableProducts;
  }
  useEffect(() => {
    if (props?.location?.pathname?.includes("subscribe")) {
      setIsSubscribeRoute(true);
    } else {
      setIsSubscribeRoute(false);
    }
  }, [props?.location?.pathname]);

  useEffect(() => {
    props.changeProductValues("isFreeEvntClicked", false);
    props.fetchOverview();
    props.fetchProducts({}, "", false);
    if (localStorage.getItem("grouppass") == "true") {
      if (localStorage.getItem("logout") == "true") {
        props.changeTransactionValues("transactionStatus", 5);
        props.changeTransactionValues("isOpen", true);
      }
      localStorage.setItem("grouppass", false);
    }
    localStorage.setItem("grouppasscompleted", false);
  }, []);

  useEffect(() => {
    if (
      props.productDetails.productList.length > 0 &&
      props.transactionDetails.recurringDataFetch
    ) {
      let subscribe_btn_clicked = localStorage.getItem(
        "subscribeButtonClicked"
      );
      let selectedProductId = localStorage.getItem("productId");
      if (
        isSubscribeRoute &&
        subscribe_btn_clicked === "true" &&
        !!selectedProductId &&
        activeSubscription?.length === 0
      ) {
        let selectedProduct = props.productDetails.productList.filter(
          (item) => item.id === selectedProductId
        );
        props.getMerchantAuthToken(selectedProduct[0].price);
        props.changeProductValues("isSubscriableProduct", true);
        props.changeTransactionValues("productPrice", selectedProduct[0].price);
        props.changeProductValues("selectedProduct", selectedProduct[0]);

        props.changeProductValues("isFreeEvntClicked", false);

        props.subscribeProduct(props.loginDetails.user, selectedProduct[0]);
        props.changeTransactionValues("transactionStatus", 2);
        props.changeTransactionValues("isOpen", true);
      } else if (
        isSubscribeRoute &&
        subscribe_btn_clicked === "true" &&
        !selectedProductId &&
        activeSubscription?.length === 0
      ) {
        props.changeTransactionValues("showDropinComponent", true);
        props.changeTransactionValues("transactionStatus", 13);
        props.changeTransactionValues("isOpen", true);
      }
    }
  }, [
    props.productDetails.productList,
    activeSubscription,
    props.transactionDetails.recurringDataFetch,
  ]);

  const getImage = (img) => {
    let imageArray = img ? img.split(",") : "";
    return imageArray && imageArray.length > 1 ? imageArray[1] : imageArray[0];
  };

  const topItemsList = () => {
    let items = translate("overview.stage.itemsList", {
      returnObjects: true,
    });
    if (
      props?.overviewDetail &&
      Object.keys(props?.overviewDetail)?.length > 0
    ) {
      var todayDate = moment(new Date(moment.utc().format()));
      var startSessionDate = moment(
        new Date(moment.utc(props?.overviewDetail?.startDiscountSale).format())
      );
      var endSessionDate = moment(
        new Date(moment.utc(props?.overviewDetail?.endDiscountSale).format())
      );
      if (
        (props?.overviewDetail?.enable1010sale === false &&
          props?.overviewDetail?.enable1010sale !== undefined) ||
        todayDate.diff(endSessionDate, "seconds") > 0
      ) {
        items.splice(0, 1);
      }
    }
    if (
      props?.overviewDetail &&
      Object.keys(props?.overviewDetail)?.length > 0
    ) {
      var todayDate = moment(new Date(moment.utc().format()));
      var endPremiumDate = moment(
        new Date(moment.utc(props?.overviewDetail?.endPremiumPass).format())
      );
      if (endPremiumDate.diff(todayDate, "seconds") <= 0) {
        items.splice(0, 1);
      }
    }
    var todayDate = moment(new Date(moment.utc().format()));
    var startSessionDate = moment(
      new Date(moment.utc(props?.overviewDetail?.startDiscountSale).format())
    );
    var endSessionDate = moment(
      new Date(moment.utc(props?.overviewDetail?.endDiscountSale).format())
    );
    var endPremiumDate = moment(
      new Date(moment.utc(props?.overviewDetail?.endPremiumPass).format())
    );
    // show both buy-cards or subscribe-card
    // if the user is not logged in OR
    // if the user is fresh user, and no single pass has bought yet
    if (
      !props.loginDetails.authenticated ||
      props.transactionDetails.expireTransactionData.length === 0
    ) {
      if (
        props?.overviewDetail?.enable1010sale &&
        todayDate.diff(endSessionDate, "seconds") < 0
      ) {
        items[0].isButtonShow =
          todayDate.diff(startSessionDate, "seconds") > 0 &&
          todayDate.diff(endSessionDate, "seconds") < 0
            ? true
            : false;
        if (endPremiumDate.diff(todayDate, "seconds") <= 0) {
          // items[1].isButtonShow = true;
          items[1].isButtonShow = true;
          items[0].buttonOnClick = () => getDegitalPass();
          items[1].buttonOnClick = () => getDegitalPass(true);
          // items[2].buttonOnClick = () => getDegitalPass(true);
        } else {
          items[1].isButtonShow = true;
          items[2].isButtonShow = true;
          items[0].buttonOnClick = () => getDegitalPass();
          items[1].buttonOnClick = () => getDegitalPass(false, true);
          items[2].buttonOnClick = () => getDegitalPass(true);
        }
      } else {
        if (endPremiumDate.diff(todayDate, "seconds") <= 0) {
          items[0].isButtonShow = true;
          // items[1].isButtonShow = true;
          items[0].buttonOnClick = () => getDegitalPass(true);
          // items[1].buttonOnClick = () => getDegitalPass(true);
        } else {
          items[0].isButtonShow = true;
          items[1].isButtonShow = true;
          items[0].buttonOnClick = () => getDegitalPass(false, true);
          items[1].buttonOnClick = () => getDegitalPass(true);
        }
      }
    } else {
      // after logged in
      let existingPasses = [];
      props.transactionDetails.expireTransactionData.map((pass) => {
        if (pass.product.isSeasonPass === "true") {
          if (pass.product.isFreeEvent === "true") existingPasses.push("basic");
          else existingPasses.push("premium");
        }
      });

      // user has premium pass
      if (existingPasses.includes("premium")) {
        if (
          props?.overviewDetail?.enable1010sale &&
          todayDate.diff(endSessionDate, "seconds") < 0
        ) {
          if (endPremiumDate.diff(todayDate, "seconds") <= 0) {
            items[0].isButtonShow = false;
            items[1].isButtonShow = false;
            // items[2].isButtonShow = false;
          } else {
            items[0].isButtonShow = false;
            items[1].isButtonShow = false;
            items[2].isButtonShow = false;
          }
        } else {
          if (endPremiumDate.diff(todayDate, "seconds") <= 0) {
            items[0].isButtonShow = false;
            // items[1].isButtonShow = false;
          } else {
            items[0].isButtonShow = false;
            items[1].isButtonShow = false;
          }
        }
      }
      // remove 2nd item (basic) and force user to buy premium
      else if (existingPasses.includes("basic")) {
        if (
          props?.overviewDetail?.enable1010sale &&
          todayDate.diff(endSessionDate, "seconds") < 0
        ) {
          items[0].isButtonShow =
            todayDate.diff(startSessionDate, "seconds") > 0 &&
            todayDate.diff(endSessionDate, "seconds") < 0
              ? true
              : false;
          if (endPremiumDate.diff(todayDate, "seconds") <= 0) {
            items[1].isButtonShow = false;
            // items[2].isButtonShow = false;
            items[0].buttonOnClick = () => getDegitalPass();
            // items[1].buttonOnClick = () => getDegitalPass(false, true);
          } else {
            items[1].isButtonShow = true;
            items[2].isButtonShow = false;
            items[0].buttonOnClick = () => getDegitalPass();
            items[1].buttonOnClick = () => getDegitalPass(false, true);
          }
        } else {
          if (endPremiumDate.diff(todayDate, "seconds") <= 0) {
            items[0].isButtonShow = false;
            // items[1].isButtonShow = false;
            // items[0].buttonOnClick = () => getDegitalPass(false, true);
          } else {
            items[0].isButtonShow = true;
            items[1].isButtonShow = false;
            items[0].buttonOnClick = () => getDegitalPass(false, true);
          }
        }
      } else {
        if (
          props?.overviewDetail?.enable1010sale &&
          todayDate.diff(endSessionDate, "seconds") < 0
        ) {
          items[0].isButtonShow =
            todayDate.diff(startSessionDate, "seconds") > 0 &&
            todayDate.diff(endSessionDate, "seconds") < 0
              ? true
              : false;
          if (endPremiumDate.diff(todayDate, "seconds") <= 0) {
            items[1].isButtonShow = true;
            // items[2].isButtonShow = true;
            items[0].buttonOnClick = () => getDegitalPass();
            items[1].buttonOnClick = () => getDegitalPass(true);
            // items[2].buttonOnClick = () => getDegitalPass(true);
          } else {
            items[1].isButtonShow = true;
            items[2].isButtonShow = true;
            items[0].buttonOnClick = () => getDegitalPass();
            items[1].buttonOnClick = () => getDegitalPass(false, true);
            items[2].buttonOnClick = () => getDegitalPass(true);
          }
        } else {
          if (endPremiumDate.diff(todayDate, "seconds") <= 0) {
            items[0].isButtonShow = true;
            // items[1].isButtonShow = true;
            items[0].buttonOnClick = () => getDegitalPass(true);
            // items[1].buttonOnClick = () => getDegitalPass(true);
          } else {
            items[0].isButtonShow = true;
            items[1].isButtonShow = true;
            items[0].buttonOnClick = () => getDegitalPass(false, true);
            items[1].buttonOnClick = () => getDegitalPass(true);
          }
        }
      }
    }

    return items;
  };

  useEffect(() => {
    if (props.transactionDetails.recurringData.length > 0) {
      let pendingPasses = props.transactionDetails.recurringData.filter(
        (pass) => {
          return (
            pass?.subscriptionresdata?.status === "Active" ||
            pass?.subscriptionresdata?.status === "Pending"
          );
        }
      );
      setActiveSubscription(pendingPasses);
    }
  }, [props.transactionDetails.recurringData]);

  return (
    <>
      <Shell.Body className="overview_body_cnt ">
        <Stage
          className={"stage-class"}
          items={{
            image: props?.overviewDetail?.stageImage,
            title: "SSOLOUNGE - Our Music Plays On",
            description: isSubscribeRoute
              ? translate("subscribe.overview.stage.discription")
              : translate("overview.stage.discription"),
            itemsList: !isSubscribeRoute && topItemsList(),
            price: "S$12.00",
            priceContent: translate("overview.stage.priceContent"),
            buttonText: isSubscribeRoute && buttonText,
            buttonOnClick: isSubscribeRoute && buttonOnClick,
            overviewDetail: props.overviewDetail,
            hideBuyButton:
              props.transactionDetails.hideBuyButton &&
              activeSubscription.length > 0,
            transactionSuccessFlag:
              props.transactionDetails.transactionSuccessFlag &&
              activeSubscription.length > 0,
          }}
        ></Stage>
        {/* <img className="banner-image" src={Banner} /> */}
      </Shell.Body>
      {!isSubscribeRoute && (
        <>
          <div className="highlight-class highlight-class-color">
            <Grid>
              <Row>
                <Column>
                  <Text as="div" className="highlight-title" to="overview">
                    <div>
                      <Link to="overview">
                        <div>HIGHLIGHT OF THE MONTH</div>
                      </Link>
                    </div>
                  </Text>
                  <div className="trailerDiv">
                    <iframe
                      src={props.overviewDetail.videoURL}
                      width="100%"
                      height="100%"
                      frameborder="0"
                      scrolling="no"
                      allow="autoplay"
                      autoplay="true"
                      allowFullScreen="true"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      oallowfullscreen="true"
                      msallowfullscreen="true"
                    ></iframe>
                  </div>
                </Column>
              </Row>
            </Grid>
          </div>
          <div className="highlight-class feature-class">
            <FeatureArtists
              className="feature-artists-class"
              featureTitle={translate("overview.FeatureArtists.featureTitle")}
              layout="horizontal"
              items={[
                {
                  image: getImage(props.overviewDetail.ftImageOne),
                  name: props.overviewDetail.ftHeadingOne,
                  description: props.overviewDetail.ftContentOne,
                },
                {
                  image: getImage(props.overviewDetail.ftImageTwo),
                  name: props.overviewDetail.ftHeadingTwo,
                  description: props.overviewDetail.ftContentTwo,
                },
                {
                  image: getImage(props.overviewDetail.ftImageThree),
                  name: props.overviewDetail.ftHeadingThree,
                  description: props.overviewDetail.ftContentThree,
                },
              ]}
            ></FeatureArtists>
            {props.overviewDetail.bookletURL &&
              props.overviewDetail.bookletURL.replace(/\s/g, "") != "" && (
                <Grid>
                  <Row>
                    <Column xs={12}>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <a
                          className="digital-booklet-link"
                          href={props.overviewDetail.bookletURL}
                          target="#"
                        >
                          <Button primary className="feature_btn">
                            View Digital Programme Booklet
                          </Button>
                        </a>
                      </div>
                    </Column>
                  </Row>
                </Grid>
              )}
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    overviewDetail: state.Overview,
    billingDetails: state.Billing,
    productDetails: state.Products,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked, sessionId, from) =>
    dispatch(
      transActions.fetchTransaction(data, buttonClicked, sessionId, from)
    ),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchOverview: () => dispatch(overviewActions.fetchOverviewDetail()),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  getMerchantAuthToken: (amount) =>
    dispatch(actions.getMerchantAuthToken(amount)),
  changeBillingValues: (f, e) =>
    dispatch(billingActions.changeBillingValues(f, e)),
  fetchProducts: (programmeId, passType, freeEventClciked) =>
    dispatch(
      prodActions.fetchProducts(programmeId, passType, freeEventClciked)
    ),
  subscribeProduct: (user, product) =>
    dispatch(transActions.subscribeProduct(user, product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
