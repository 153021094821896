import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Column,
  Text,
  Button,
  BouncerContextHook,
  SisticLivePlayerAlt,
  SisticDecastPlayer,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Donation from "../assets/images/donation.png";
import Arrow from "../assets/images/leftarrow.png";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import * as VideoOnDemand from "../actions/VideoOnDemand";
import * as userActions from "../actions/UserActivities";
import history from "../stores/history";
import LiveChat from "./LiveChat";
import DonationLogo from "../assets/images/donation-logo.png";
import { userDetails, numberToString } from "../utils/utils";
import { pushEventToData } from "../utils/Datalayer";

function VideoOnDemandDetail(props) {
  const [isEventPush, setIsEventPush] = useState(false);
  const [endPremiumDate, setEndPremiumDate] = useState(false);
  const loginContext = BouncerContextHook();
  let onClose = () => {
    props.changeTransactionValues("productPrice", 0);
    props.changeTransactionValues("showDropinComponent", false);
    props.changeTransactionValues("transactionStatus", 9);
    props.changeTransactionValues("checkconsent", false);
    props.changeTransactionValues("isOpen", false);
    localStorage.setItem("buttonClicked", false);
  };
  let getDegitalPass = async (e, isFree) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", isFree ? "seasonfreepass" : "seasonpass");
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          "",
          ""
        );
      }
    }, 1000);
  };

  let getSingleDegitalPass = async (e) => {
    let progammeId = props.match.params.id;
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    localStorage.setItem("pass_type", "singlepass");
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked"),
          progammeId,
          ""
        );
      }
    }, 1000);
  };

  const sortByProperty = (property) => {
    return (a, b) => {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    };
  };

  useEffect(() => {
    props.changeProductValues("isFreeEvntClicked", false);
    let progammeId = props.match.params.id;
    if (process.env.REACT_APP_DRYRUN_PLAYBACK_IDS.includes(progammeId)) {
      props.fetchDryRunPlaybackDetail(progammeId);
    } else {
      props.fetchVideoOnDemandDetail(progammeId);
    }
    return () => {
      props.changeVideoOnDemandValues("videoOnDemandDetail", []);
    };
  }, []);

  useEffect(() => {
    var todayDate = moment(new Date(moment.utc().format()));
    var endPremiumDate = moment(
      new Date(moment.utc(props?.overviewDetail?.endPremiumPass).format())
    );
    setEndPremiumDate(endPremiumDate.diff(todayDate, "seconds") <= 0);
  }, [props.overviewDetail]);

  useEffect(() => {
    if (props.videoOnDemand.videoOnDemandDetail.length > 0) {
      let progammeId = props.match.params.id;
      props.fetchCheckProducts(
        progammeId,
        "singlePass",
        props.videoOnDemand.videoOnDemandDetail[0].isFreeEvent
      );
    }
  }, [props.videoOnDemand.videoOnDemandDetail]);

  useEffect(() => {
    if (props.productDetails.checkProductList.length > 0) {
      props.productDetails.checkProductList.forEach((expireProd) => {
        let expireInterval = null;
        let isFixedExpiryDate = !!expireProd.isFixedExpiryDate
          ? expireProd.isFixedExpiryDate
          : false;
        let validityInMonths = !!expireProd.validityInMonths
          ? parseInt(expireProd.validityInMonths)
          : 12;
        if (!isFixedExpiryDate) {
          let expdt = new Date();
          expireProd.expiresOn = new Date(
            expdt.setMonth(expdt.getMonth() + validityInMonths)
          ).toISOString();
        }
        let utcString = new Date(expireProd.expiresOn).toUTCString();
        expireInterval = setInterval(function () {
          var serverDate = moment(new Date(moment.utc().format()));
          var indiaDate = moment(new Date(moment.utc(utcString).format()));
          if (indiaDate.diff(serverDate, "seconds") < 1) {
            props.changeProductValues(
              "checkProductList",
              props.productDetails.checkProductList.filter(
                (item) => item.id !== expireProd.id
              )
            );
            clearInterval(expireInterval);
          }
        }, 1000);
      });
    }
  }, [props.productDetails.checkProductList]);

  const getSynopsis = (writeUp) => {
    let regex = /.*SYNOPSIS.*?(?=\<br>)<br>(?<synopsis>.+?(?=\<br>|&))/;
    let { groups } = writeUp.match(regex);
    return groups.synopsis;
  };

  const registerUserLogs = async (eventType, otherEventInfo) => {
    let deviceDetail = await userDetails().then((data) => {
      return data;
    });
    props.createUserLogs(
      {
        ...props.loginDetails.user,
        sessionId: props.loginDetails.keycloak.sessionId,
      },
      deviceDetail,
      {
        ...props.videoOnDemand.videoOnDemandDetail[0],
        eventDetailInfo: JSON.stringify({ ...otherEventInfo }, numberToString),
      },
      eventType
    );
  };

  useEffect(() => {
    if (
      props?.videoOnDemand?.videoOnDemandDetail?.length > 0 &&
      props?.loginDetails?.authenticated &&
      (props.transactionDetails.hideBuyButton ||
        (props.transactionDetails.expireTransactionData.length > 0 &&
          props.transactionDetails.expireTransactionData.some(
            (single) =>
              single.product.sessionIds &&
              single.product.sessionIds.includes(
                props?.videoOnDemand?.videoOnDemandDetail[0]?.id
              )
          )) ||
        (props.videoOnDemand.videoOnDemandDetail[0].isFreeEvent &&
          props.transactionDetails.expireTransactionData.some(
            (single) =>
              single.product?.isSeasonPass === "true" &&
              single.product?.isFreeEvent === "true"
          ))) &&
      !isEventPush
    ) {
      pushEventToData("stream_watched", [
        {
          item_id: props?.videoOnDemand?.videoOnDemandDetail[0]?.id,
          item_name: props?.videoOnDemand?.videoOnDemandDetail[0]?.title
            ?.replace(/(<([^>]+)>)/gi, "")
            ?.replace(/(?:&nbsp;|amp;)/g, ""),
          start_time: moment(new Date())
            .tz("Asia/Singapore")
            .format("DD MMM YYYY hh:mm:ss a"),
        },
      ]);
      setIsEventPush(true);
    }
  }, [
    props.videoOnDemand.videoOnDemandDetail,
    props.loginDetails.authenticated,
    props.transactionDetails,
    isEventPush,
  ]);

  return (
    <>
      {props.videoOnDemand.videoOnDemandDetail.length > 0 &&
        props.videoOnDemand.videoOnDemandDetail.map((eachValue, key) => {
          const inPreviewMode = localStorage.getItem("inPreviewMode");
          const value =
            inPreviewMode === "true" && eachValue.preview
              ? eachValue.preview
              : eachValue;
          let progammeSessionId = props.match.params.id;
          let imageArray = value.thumbNail ? value.thumbNail.split(",") : "";
          let image =
            imageArray && imageArray.length > 1 ? imageArray[1] : imageArray[0];
          let synopsis = value.synopsis ?? getSynopsis(value.writeUp);
          return (
            <div className="row vodContainer adjustment">
              <div className="container">
                <div className="title title-nav">
                  <Link
                    to={
                      localStorage.getItem("prevPath")
                        ? localStorage.getItem("prevPath")
                        : "/"
                    }
                    className="title"
                  >
                    <img src={Arrow} alt="icon" />
                  </Link>
                  <div className="vod-title">
                    <div dangerouslySetInnerHTML={{ __html: value.title }} />
                  </div>
                </div>
                <div className="wholediv">
                  <div className="videodiv voddiv disablechat">
                    {props.loginDetails.authenticated &&
                      (props.transactionDetails.hideBuyButton ||
                        (props.transactionDetails.expireTransactionData.length >
                          0 &&
                          props.transactionDetails.expireTransactionData.some(
                            (single) =>
                              single.product.sessionIds &&
                              single.product.sessionIds.includes(
                                progammeSessionId
                              )
                          )) ||
                        (value.isFreeEvent &&
                          props.transactionDetails.expireTransactionData.some(
                            (single) =>
                              single.product?.isSeasonPass === "true" &&
                              single.product?.isFreeEvent === "true"
                          ))) &&
                      (value?.turnOnStaticBanner && value?.staticBanner ? (
                        <img
                          src={value?.staticBanner}
                          width="100%"
                          height={"100%"}
                        />
                      ) : (
                        <SisticLivePlayerAlt
                          className="video-player"
                          bgColor="white"
                          color="black"
                          playerItems={{
                            src: value?.videoURL,
                            stopChat: value?.chatFlag ? !value?.chatFlag : true,
                            registerUserLogs: registerUserLogs,
                            enableChat: value?.chatFlag,
                            chatContent: (
                              <LiveChat session={props.match.params.id} />
                            ),
                          }}
                        />
                      ))}
                    {/* {props.loginDetails.authenticated === true &&
                    props.transactionDetails.hideBuyButton === false ? (
                      <div className="wrap_div">
                        <img src={image} alt=""></img>
                        <div className="synopsis">
                          <strong>SYNOPSIS</strong>
                          <br />
                          <div className="synopsis_para">
                            <p>
                              {ReactHtmlParser(
                                synopsis.replace(/(?:\r\n|\r|\n)/g, "<br />")
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="button-group">
                          <Text as="h4" className="wrap_title">
                            {
                              "This video is only available upon login with your Access Pass"
                            }
                            <br />
                          </Text>
                          <div>
                            <Text as="h6" className="wrap_pass">
                              {"Don't Have an Access Pass yet?"}
                              <br />
                            </Text>
                            <Button
                              primary
                              className="video_btn"
                              onClick={(e) => {
                                getDegitalPass(e);
                              }}
                            >
                              Get your Access Pass here
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {props.loginDetails.authenticated === null ||
                    props.loginDetails.authenticated === false ||
                    (props.transactionDetails.hideBuyButton === false &&
                      (!props.transactionDetails.expireTransactionData.length >
                        0 ||
                        (!props.transactionDetails.expireTransactionData.some(
                          (single) =>
                            single.product.sessionIds &&
                            single.product.sessionIds.includes(
                              progammeSessionId
                            )
                        ) &&
                          (!value.isFreeEvent ||
                            !props.transactionDetails.expireTransactionData.some(
                              (single) =>
                                single.product?.isSeasonPass === "true" &&
                                single.product?.isFreeEvent === "true"
                            ))))) ? (
                      <div className="wrap_div">
                        <img src={image} alt=""></img>
                        <div className="synopsis">
                          <strong>SYNOPSIS</strong>
                          <br />
                          <div className="synopsis_para">
                            {ReactHtmlParser(
                              synopsis
                                .split("<a")
                                .join('<a target="_blank"')
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                            )}
                          </div>
                        </div>
                        <div className="button-group">
                          <div>
                            {value.isFreeEvent ? (
                              <div>
                                <Text as="h4" className="wrap_title">
                                  {`This video is only available upon login with ${
                                    !endPremiumDate
                                      ? "any one of the following passes"
                                      : "an existing Premium All-Access / Basic Access Pass"
                                  }.`}
                                </Text>
                                {props.productDetails.checkProductList.length >
                                  0 && (
                                  <div style={{ marginTop: "5vh" }}>
                                    <Button
                                      primary
                                      className="video_btn"
                                      onClick={(e) => {
                                        props.changeProductValues(
                                          "isFreeEvntClicked",
                                          true
                                        );
                                        getSingleDegitalPass(e);
                                      }}
                                    >
                                      Register Free Event
                                    </Button>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                <Text as="h4" className="wrap_title">
                                  {`This video is only available upon login with ${
                                    !endPremiumDate
                                      ? "any one of the following passes"
                                      : "an existing Premium All-Access / Festival / Single Access Pass"
                                  }.`}
                                </Text>
                                {props.productDetails.checkProductList.length >
                                  0 && (
                                  <div>
                                    {props.productDetails.singlePassList
                                      .length > 0 && (
                                      <div>
                                        <Text as="h6" className="wrap_pass">
                                          Only purchasing this video?
                                        </Text>
                                        <Button
                                          primary
                                          className="video_btn"
                                          onClick={(e) => {
                                            props.changeProductValues(
                                              "isBundleEvent",
                                              false
                                            );
                                            getSingleDegitalPass(e);
                                          }}
                                        >
                                          Buy a Single Access Pass
                                        </Button>
                                      </div>
                                    )}
                                    {props.productDetails.bundlePassList
                                      .length > 0 && (
                                      <div>
                                        <Text as="h6" className="wrap_pass">
                                          {eachValue.festivePromptText
                                            ? eachValue.festivePromptText
                                            : "Only purchasing festival video?"}
                                        </Text>
                                        <Button
                                          primary
                                          className="video_btn"
                                          onClick={(e) => {
                                            props.changeProductValues(
                                              "isBundleEvent",
                                              true
                                            );
                                            getSingleDegitalPass(e);
                                          }}
                                        >
                                          {eachValue.festiveBtnText
                                            ? eachValue.festiveBtnText
                                            : "Buy a Festival Pass"}
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {value.isFreeEvent && (
                              <div>
                                <Text as="h6" className="wrap_pass">
                                  Want access to all free videos?
                                </Text>
                                <Button
                                  primary
                                  className="video_btn"
                                  onClick={(e) => {
                                    getDegitalPass(e, true);
                                  }}
                                >
                                  Get a 12M Basic Access Pass
                                </Button>
                              </div>
                            )}
                            {!endPremiumDate && (
                              <div>
                                <Text as="h6" className="wrap_pass">
                                  Want access to all videos?
                                </Text>
                                <Button
                                  primary
                                  className="video_btn"
                                  onClick={(e) => {
                                    getDegitalPass(e, false);
                                  }}
                                >
                                  Buy a 12M Premium All-Access Pass
                                </Button>
                              </div>
                            )}
                            {props.loginDetails.authenticated === null ||
                            props.loginDetails.authenticated === false ? (
                              <div>
                                <Text as="h6" className="wrap_pass">
                                  Already have a pass?
                                </Text>
                                <Button
                                  primary
                                  className="video_btn"
                                  onClick={(e) => {
                                    getDegitalPass(e, false);
                                  }}
                                >
                                  Click here to login
                                </Button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="synopiscontainer">
                <div className="synopisdiv">
                  <div className="synopissection">
                    <Text as="p">
                      {value.writeUp &&
                        ReactHtmlParser(
                          value.writeUp
                            .split("<a")
                            .join('<a target="_blank"')
                            .replace(/(?:\r\n|\r|\n)/g, "<br />")
                        )}
                    </Text>
                  </div>
                  <div className="chatsection">
                    <div className="videoDetailFeature">
                      <Text as="h4">
                        <b>VIDEO HIGHLIGHTS</b>
                      </Text>
                      <Text as="p">
                        {value.featuredPresenters
                          ? ReactHtmlParser(
                              value.featuredPresenters
                                .split("<a")
                                .join('<a target="_blank"')
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                            )
                          : null}
                      </Text>
                      {value.bookletURL ? (
                        <a
                          href={value.bookletURL}
                          className="digital-booklet-link"
                          target="#"
                        >
                          <Button className="detail_btn">
                            View Digital Programme Booklet
                          </Button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="bookwebsection">
                  <div className="booksection">
                    {value.donationFlag && (
                      <a
                        href={"https://www.giving.sg/sso"}
                        target="_blank"
                        style={{
                          display: "flex",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src={Donation}
                          alt="icon"
                          className="donation-img"
                        />
                        <div className="giving-link">
                          <img
                            src={DonationLogo}
                            alt="icon"
                            className="donation-logo"
                          />
                          <Column className="booktext">
                            <Text as="p">
                              Support the SSO
                              <br />
                            </Text>
                            <Text as="p" className="donation-link">
                              giving.sg/sso
                            </Text>
                          </Column>
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    videoOnDemand: state.VideoOnDemand,
    productDetails: state.Products,
    overviewDetail: state.Overview,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked, sessionId, from) =>
    dispatch(
      transActions.fetchTransaction(data, buttonClicked, sessionId, from)
    ),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeVideoOnDemandValues: (f, e) =>
    dispatch(VideoOnDemand.changeVideoOnDemandValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  fetchCheckProducts: (programmeId, passType, isFreeEvent) =>
    dispatch(
      prodActions.fetchCheckProducts(programmeId, passType, isFreeEvent)
    ),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchVideoOnDemandDetail: (progammeId) =>
    dispatch(VideoOnDemand.fetchVideoOnDemandDetail(progammeId)),
  fetchDryRunPlaybackDetail: (progammeId) =>
    dispatch(VideoOnDemand.fetchDryRunPlaybackDetail(progammeId)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
  changeUserActivityValues: (f, e) =>
    dispatch(userActions.changeUserActivityValues(f, e)),
  createUserLogs: (user, device, session, eventType) =>
    dispatch(userActions.createUserLogs(user, device, session, eventType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoOnDemandDetail);
