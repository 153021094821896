import React, { useEffect, useState } from "react";
import {
  Shell,
  Carousel,
  Card,
  Text,
  Button,
  Scheduler,
  LiveIcon,
  RightArrowIcon,
  Dropdown,
} from "@StixNext/mojito-ui";
import { Column, Grid, Row, SearchInput } from "@StixNext/mojito-ui";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../actions/Programmes";
import * as prodActions from "../actions/Products";
import * as Images from "./common/watch-free";
import comingsoon from "../assets/images/coming-soon.jpg";
import { displayFilter, formatDate } from "../utils/utils";
import liveTag from "../assets/images/liveTag.png";
import LikeBox from "./LikeBox";
import comingsoonMob from "../assets/images/watch_now_mobile.jpg";
import comingsoonDesk from "../assets/images/watch_now_desk.jpg";
import comingsoonIpad from "../assets/images/watch_now_ipad.jpg";
import StarIcon from "../assets/images/star_icon.svg";
import GreyOverlay from "../assets/images/grey_overlay.svg";

const Livestream = (props) => {
  const {
    programmesList = [],
    fetchProgrammes,
    history,
    dateSelected,
    setDateSelected,
  } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };
  let defultChannel = [{ channelId: 1 }];
  const [mobchannel, setMobChannel] = useState(defultChannel);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(defultChannel[0]);
  const [slidesEvents, setSlidesEvents] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const updatedOptions = { year: "numeric", month: "short" };
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  const [sortType, setSortType] = useState("1");
  const sortOptions = [
    { text: "Latest Release", value: "1" },
    { text: "Alphabetical Order (A - Z)", value: "2" },
    { text: "Alphabetical Order (Z - A)", value: "3" },
  ];

  const onSortChange = (option) => {
    setSortType(option);
  };

  const isOverlay = (slide) => {
    if (!props.loginDetails.authenticated) return false;
    else if (slide.isFreeEvent) return false;
    else if (
      props.loginDetails.authenticated &&
      (props.transactionDetails.hideBuyButton ||
        (props.transactionDetails.expireTransactionData.length > 0 &&
          props.transactionDetails.expireTransactionData.some(
            (single) =>
              single.product.sessionIds &&
              single.product.sessionIds.includes(slide.id)
          )) ||
        props.watchpartyDetails.invitationSessionIds.includes(slide.id))
    )
      return false;
    else return true;
  };

  const removeTags = (title) =>
    title.replace(/(<([^>]+)>)/gi, "").replace(/(?:&nbsp;|amp;)/g, "");

  useEffect(() => {
    props.changeProductValues("isFreeEvntClicked", false);
    fetchProgrammes();
    setDateSelected("all");
  }, []);

  useEffect(() => {
    handleChannelView();
  }, [selectedChannel]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleChannelView = () => {
    let filterChannel = defultChannel.filter(
      (item) => item.channelId == selectedChannel
    );
    let defultValue = defultChannel.length > 0 ? defultChannel[0] : {};
    filterChannel = filterChannel.length == 0 ? [defultValue] : filterChannel;
    setMobChannel(filterChannel);
  };

  const formatDropdownDate = (date) => {
    let options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const [weekday, month, day, year] = new Date(date)
      .toLocaleDateString(undefined, options)
      .replace(/,/g, "")
      .split(" ");
    return `${weekday}, ${month} ${day} ${year}`;
  };

  const sortByProperty = (property) => {
    return (a, b) => {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    };
  };

  const onFilter = (e) => {
    setFilterValue(e.target.value);
  };

  const setScheduleFilter = () => {
    const sortField = sortType !== "1" ? "title" : "startTime";
    const filteredProgrammesList = programmesList.filter((item) =>
      item.isNewRelease ? item.isNewRelease == false : true
    );
    let data = filteredProgrammesList
      .sort(sortByProperty("startTime"))
      .reverse()
      .filter((slide) => {
        let title = slide.title;
        let regex = /<.+>(?<title>.+)<.+>/;
        let output = title.match(regex);
        return filterValue
          ? output[1].toUpperCase().includes(filterValue.toUpperCase()) &&
              new Date(slide.startTime) < tomorrow
          : new Date(slide.startTime) < tomorrow;
      })
      .sort((a, b) =>
        (sortType === "1"
          ? new Date(b[sortField])
          : sortType === "2"
          ? removeTags(a[sortField])
          : removeTags(b[sortField])) >
        (sortType === "1"
          ? new Date(a[sortField])
          : sortType === "2"
          ? removeTags(b[sortField])
          : removeTags(a[sortField]))
          ? 1
          : -1
      );
    data =
      dateSelected == "all"
        ? data
        : data.filter(
            (programme) =>
              formatDropdownDate(programme.startTime) == dateSelected
          );
    setSlidesEvents(data);
  };

  useEffect(() => {
    let dates = [];
    let todaySlides = [];
    let today = new Date().toLocaleDateString();
    let selectDate = null;
    programmesList
      .filter((slide) => {
        let title = slide.title
          ? slide.title
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/(?:&nbsp;|amp;)/g, "")
          : "";
        let writeUp = slide.writeUp
          ? slide.writeUp
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/(?:&nbsp;|amp;)/g, "")
          : "";
        let featuredPresenters = slide.featuredPresenters
          ? slide.featuredPresenters
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/(?:&nbsp;|amp;)/g, "")
          : "";
        let synopsis = slide.synopsis
          ? slide.synopsis
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/(?:&nbsp;|amp;)/g, "")
          : "";

        return filterValue
          ? (title.toUpperCase().includes(filterValue.toUpperCase()) ||
              writeUp.toUpperCase().includes(filterValue.toUpperCase()) ||
              featuredPresenters
                .toUpperCase()
                .includes(filterValue.toUpperCase()) ||
              synopsis.toUpperCase().includes(filterValue.toUpperCase())) &&
              new Date(slide.startTime) < tomorrow
          : new Date(slide.startTime) < tomorrow;
      })
      .map((programmes) => {
        programmes["buttonEnabled"] = programmes.QAFlag ? true : false;
        dates.push(programmes.startTime);
        let programmesDate = new Date(
          programmes.startTime
        ).toLocaleDateString();
        if (today == programmesDate) {
          let current = moment(programmes.endDateTime).diff(moment(), "minute");
          if (current > 0) {
            todaySlides.push(programmes);
          }
          selectDate = programmes.date;
        }
      });
    if (todaySlides.length != 0) setSlidesEvents(todaySlides);
    let uniqueDates = dates
      .map((date) => formatDropdownDate(date))
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((item) => ({ text: item, value: item }));
    uniqueDates.unshift({ text: "All", value: "all" });
    setDateOptions(uniqueDates);
    setScheduleFilter(dateSelected);
  }, [programmesList]);

  useEffect(() => {
    setScheduleFilter(dateSelected);
  }, [dateSelected, filterValue, sortType]);

  const handleClick = (eventId) => {
    localStorage.setItem(
      "pathDetail",
      JSON.stringify({ eventId, path: "watch" })
    );
    history.push(`/watch/${eventId}`);
  };
  return (
    <Shell.Body className="playback-container">
      <div className="toggle-div">
        <div className="card-filter">
          <SearchInput placeholder="Keyword Search" onChange={onFilter} />
        </div>
        <div className="toggle-cover">
          <Grid>
            <Row className="sort_by_row">
              <Text as={"h5"} className="show-date">
                Sort By
              </Text>
              <Dropdown
                id="sort-dropdown"
                options={sortOptions}
                mulitple={false}
                Width={250}
                value={sortType}
                placeholder="Select"
                onChange={(item) => onSortChange(item.value)}
              />
            </Row>
          </Grid>
        </div>
      </div>
      {(filterValue || slidesEvents.length > 0) && (
        <Grid>
          <Row style={{ alignItems: "center", margin: "25px 0 35px 0" }}>
            <Text as={"h5"} className="show-date">
              Show Date
            </Text>
            <Dropdown
              id="dropdown"
              options={dateOptions}
              mulitple={false}
              Width={250}
              value={dateSelected}
              placeholder="Select a Date"
              onChange={(item) => {
                setDateSelected(item.value);
              }}
            />
          </Row>
          <Row>
            <div className={"line-in-upcoming"} />
          </Row>
          <Row>
            {slidesEvents.map((slide, index) => {
              let isLive = false;
              let videoDate = new Date(slide.startTime).toLocaleDateString();
              let today = new Date().toLocaleDateString();
              if (videoDate == today) {
                let videoFrom = new Date(slide.startTime)
                  .toLocaleString("en-US", {
                    hour: "2-digit",
                    hour12: false,
                    minute: "2-digit",
                  })
                  .replace(":", "");
                let videoTo = new Date(
                  new Date(slide.startTime).getTime() + slide.duration * 60000
                )
                  .toLocaleString("en-US", {
                    hour: "2-digit",
                    hour12: false,
                    minute: "2-digit",
                  })
                  .replace(":", "");
                let currentTime = new Date().toLocaleString("en-US", {
                  hour: "2-digit",
                  hour12: false,
                  minute: "2-digit",
                });
                if (currentTime >= videoFrom && currentTime <= videoTo)
                  isLive = true;
              }
              let imageArray = slide.thumbNail
                ? slide.thumbNail.split(",")
                : "";
              let image =
                imageArray && imageArray.length > 1
                  ? imageArray[1]
                  : imageArray[0];
              return (
                <div className="contestant-tile" key={index}>
                  <Card
                    key={index}
                    className={"video-slides live_tag_parent"}
                    onClick={() => handleClick(slide?.id)}
                    noPadding={false}
                    noBoxShadow={false}
                    noBorder={true}
                    noBorderRadius={true}
                  >
                    {isOverlay(slide) && (
                      <img className="overlay_img" src={GreyOverlay} alt="" />
                    )}
                    <img src={image} alt="" />
                    {props.watchpartyDetails.invitationSessionIds.includes(
                      slide.id
                    ) && (
                      <div
                        className={`vip_tag ${
                          !slide.isFreeEvent && "vip-container"
                        }`}
                      >
                        <div className="vip_tag_star">
                          <img src={StarIcon} />
                        </div>
                        <div className="vip_tag_text">
                          <Text>VIP Room</Text>
                        </div>
                      </div>
                    )}
                    {!slide.isFreeEvent && (
                      <div
                        className={`premium-tag ${
                          isOverlay(slide) && "overlay_premium"
                        }`}
                      >
                        <div className="premium-tag-text">
                          <Text>Premium</Text>
                        </div>
                      </div>
                    )}
                    {isLive && (
                      <div className={"live_tag"}>
                        <img src={liveTag} />
                      </div>
                    )}
                    <div className="meta-details">
                      <div
                        className={"title-tiles vod-tiles"}
                        dangerouslySetInnerHTML={{ __html: slide?.title }}
                      />
                      <div className="meta-data">
                        <div className="detail-box">
                          <Text as="p" className="style-p">
                            {"Duration: " + slide?.duration + " mins"} <br />
                          </Text>
                          <Text as="p" className="style-p">
                            {"Premieres: " + formatDate(slide.startTime)}{" "}
                          </Text>
                        </div>
                        <LikeBox slide={slide} />
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </Row>
        </Grid>
      )}
      {!filterValue && slidesEvents.length == 0 ? (
        <>
          <div className="coming-soon">
            <img src={comingsoonIpad} />
          </div>
          <div className="coming-soon-mob">
            <img src={comingsoonMob} />
          </div>
          <div className="coming-soon-desk">
            <img src={comingsoonDesk} />
          </div>
        </>
      ) : (
        ""
      )}
    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  programmesList: displayFilter(state.Programmes.programmesList),
  dateSelected: state.Programmes.dateSelected,
  watchpartyDetails: state.WatchParty,
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProgrammes: () => dispatch(actions.fetchProgrammes()),
  setDateSelected: (date) => dispatch(actions.setDateSelected(date)),
  changeProductValues: (f, e) =>
    dispatch(prodActions.changeProductValues(f, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Livestream);
